.claim-step-info {
  margin-top: 20px;
  margin-bottom: 20px;

  &__modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 800px;
    background-color: #fff;
    border: 1px solid #de6d19;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 11px 15px -7px;
    padding: 35px;
  }

  &__step {
    min-height: 40px;

    text-transform: none;
    border-radius: 28px;
    border: 1px solid;
    margin-right: 15px;
    color: #3c546e;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;

    &--selected {
      background-color: #3c546e;
      box-shadow: none;

      &:hover {
        background-color: #3c546e;
        box-shadow: none;
      }

      label {
        color: white;
      }
    }

    &--cancel {
      position: relative;
      float: right;
      background-color: white;
      box-shadow: none;
      color: #de6d19;

      &:hover {
        background-color: #de6d19;
        box-shadow: none;
        color: white;
      }

      label {
        color: de6d19;
      }
    }

    &--selectedOrange {
      position: relative;
      float: right;
      background-color: #de6d19;
      box-shadow: none;
      color: white;

      &:hover {
        background-color: #de6d19;
        box-shadow: none;
      }

      label {
        color: white;
      }
    }

    &--disabled {
      background-color: #c6c7c8;
    }
  }

  &__boxlabel {
    margin: 0 0 1rem;
    height: 32px;
    width: 120.5458984375px;
    left: 117px;
    top: 276.3828125px;
    display: flex;
    align-items: center;

    label {
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      display: flex;
      align-items: center;
      color: rgba(0, 0, 0, 0.8);
    }
  }

  [aria-selected='true'] {
    background-color: #3c546e !important;
    color: white !important;
  }

  .MuiTab-wrapper {
    width: 100%;
    display: inline-flex;
    align-items: center;
    flex-direction: row;
    gap: 10px;

    img {
      margin-bottom: 0 !important;
      height: 20px;
    }
  }

  .MuiTab-labelIcon {
    min-height: 50px;
    padding-top: 5px;

    img {
      margin-bottom: 0 !important;
      height: 20px;
    }
  }

  .box-content {
    margin-top: 1rem;
    left: 0.01%;
    right: 0%;
    top: 1rem;
    bottom: 0.01%;
    background: #f9fcff;
    border-radius: 3px;
    
    label {
      left: 3.06%;
      right: 65.95%;
      top: 33.97%;
      bottom: 62.48%;
      font-style: normal;
      font-weight: 700;
      font-size: 19px;
      line-height: 21px;
      display: flex;
      align-items: center;
      color: #000000;

    }

    .MuiInputLabel-formControl {
      top: auto;
      font-weight: 400;
    }

    .caption {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      display: flex;
      align-items: center;
      letter-spacing: 0.4px;
      color: #7d92a1;
    }
  }

  .information {
    label {
      display: inline-block;
      padding-right: 0.7rem;
    }

    div {
      padding-top: 20px;
    }

    ul {
      margin-top: -3px;
    }
  }

  .TableContainer {
    box-shadow: none;
    margin-top: 1rem;

    thead th {
      background-color: #f8fcff;
    }

    tbody th,
    td {
      background-color: #e9f0f7;
    }
  }

  .ObservationsContent {
    padding-top: 1rem;

    .ObservationText {
      padding-left: 3rem;
      text-transform: none;
      font-weight: 600;
      color: #54565a;
      padding-top: 1rem;
      padding-bottom: 1rem;
    }

    .list-rigth-content {
      display: grid;
    }

    .icon-float-rigth {
      float: right;
    }

    .chip-status {
      border-radius: 4px;
    }
  }

  .PhotosContent {
    padding-top: 3rem;
    margin-left: 0rem;
   
    .text-description {
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.8px;
      color: #001d3d;
      flex: none;
      order: 0;
      flex-grow: 0;
      margin-top: 10px;
    }

    p {
      padding-left: '1rem';
    }

    .default-card {
      background: #c9cfd6;
      box-shadow: none;
    }
  }

  .scrollable-container {
    max-height: 350px;
    overflow: auto;
    margin-right: -21px;
    margin-bottom: -20px;
  }

  .close-icon {
    margin-left: 10px;
  }

  .btnAdd {
    float: right;
  }

  .tdstyle {
    margin-right: 22px;
    width: 100px;
  }

  .docTypeText {
    background-color: #EDEDED;
    padding: 2px 10px 2px 10px;
    color: blue;
    border-radius: 10px;
    margin-top: 10px;
    margin-left: -20px;
    text-transform: uppercase;
    font-size: 13px;
  }
}

.claim-doc-upload {

  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 24;

  .titleUploadDoc {
    font-size: 34px;
    font-weight: bold;
    letter-spacing: -2px;
    color: black;
    line-height: 30px;
  }

  .btnCargarDoc {
    border-radius: 5px;
    padding: 7px;
    width: 150px;
    text-align: center;
    border: 1px solid #FF5733;
    color: #FF5733;
    font-size: 12px;
    text-transform: uppercase;
    margin-top: 12px;
    cursor: pointer;
  }

  .selectDocType {
    margin-top: 10px;
  }

  .btnCancelarUD {
    border: 1px solid #FF5733;
    color: #FF5733;
    background-color: white;
    margin-right: 10px;
  }

  .btnCancelarUD:hover {
    background-color: #EDEDED;
  }

  .imageLoaded {
    width: 150px;
    height: 150px;
    cursor: default;
  }
}

.documents-section-container {
  width: calc(100% - 25px);

  .documents_head_menu {
    // display: flex;
    margin-top: 10px;
    position: fixed;
    width: calc(100% - 120px);
    background-color: white;
    z-index: 5;   
    padding: 10px 0 10px 0;
  }

  .documents_left_head {
    float: left;
    display: flex;
    margin-right: '25px';

    &_selectfilter {
      display: flex;
      margin-left: 25px;
    }


    .selectClasification {
      height: 40px;
      margin-left: 10px;
      background-color: #ffffff;
      border: 1px solid #87a5a8;
      border-radius: 5px;
      font-size: 1em;
      padding: 0 10px 0 10px;
      color: #5f6667;
    }

    .selectClasification:focus {
      outline: none;
    }

    .labelClasification {
      height: 40px;
      line-height: 38px;
      margin-left: 30px;
    }
  }
}

/* Estilos para pantallas más pequeñas (menos de 1000px de ancho) */
@media (max-width: 1000px) {
  .documents-section-container {
    .documents_left_head {
      flex-direction: column;

      &_selectfilter {
        flex-direction: column;
        margin-left: 0px;
      }
    }
  }



  .claim-step-info {

    .PhotosContent {
      padding-top: 8rem;
    }

    .btnAdd {
      float: none;
    }
  }

  .claim-doc-upload {
    .container {
      text-align: center;
    }

    .fileUpControl {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .selectDocType {
      margin-top: 25px;
    }

    .imageLoaded {
      margin: auto;
      text-align: center;
    }
  }


}