@import 'assets/connect/styles/_variables';

.filters-claims {
  display: flex;
  margin-bottom: 30px;
  margin-left: -1rem;
  flex-wrap: wrap;
  padding: 0 16px 0 16px;

  & > *,
  > .MuiAutocomplete-root {
    margin: 10px 10px 0;
  }

  .MuiFormControlLabel-root {
    margin-top: 0;
  }

  &__label-filter {
    color: #001d3d;
    text-align: center;
    font-size: 16px;
    font-family: 'InterRegular';
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.44px;
    margin-left: 1rem;
  }

  &__stages {
    display: flex;
    height: 55px;
    padding: 10px 15px;
    justify-content: center;
    align-items: center;
    border-radius: 28px;
    border: 1px solid #e5e5e5;

    color: #3c546e;
    text-align: center;
    font-size: 14px;
    font-family: Connect;
    font-weight: 700;
    line-height: 22.4px;
    letter-spacing: 0.8px;
    text-transform: none;

    &--selected {
      background-color: #3c546e;
      color: white;

      &:hover {
        background-color: #3c546e;
        color: white;
      }
    }
  }

  &__toggle-events {
    border-right: 1px solid;
    padding-right: 20px;
  }

  &__dropdown {
    margin-left: 20px;
    width: 190px;
  }
}

.MuiAutocomplete-root {
  margin: 0 18px;
}

.MuiInputBase-root.MuiInput-underline:hover,
.MuiInputBase-root.MuiInput-underline:hover,
.MuiInputBase-root.MuiInput-underline::before,
.MuiInputBase-root.MuiInput-underline::after {
  border: none !important; // tried many things, but only this worked.
}
