@import 'assets/connect/styles/_variables';

$sideMargin: 16px;

$xMargin: 12px;

.dashboardGridContainer{
  width:100%;

  &_title{
    font-Weight: bold; 
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
  }

  &_link{
    display: flex;
    color: #586983;
  }
}

.gridCard-claim {
  border-radius: 8px;
  border: 1px solid rgba(224, 224, 224, 1);
  position: relative;
  min-height: 60vh;

  &_title {
    color: #000;
    font-size: 18px;
    font-family: 'Connect';
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.8px;
    text-transform: uppercase;
  } 
}

.titleFont-claim {
  color: $primary;
}

.eventStatuses-claim {
  display: flex;
  margin-bottom: 20px;
  padding-top: 20px;

  &__icon {
    display: flex;
    text-align: center;
    background-color: #f8f8f8;
    padding: 20px;

    &--content {
      background-color: #d9d9d9;
      height: 100px;
      width: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__card {
    &--notif {
      border-radius: 10px;
      cursor: auto;
      opacity: 1;
      color: #000;
    }

    &--small {
      flex: 0.4;
    }

    &--md {
      flex: 1;
    }

    &--big {
      flex: 2;
    }
  }

  &> :not(:last-child) {
    margin-right: 0;
  }

  label{
    font-size: 15px;
    font-weight: 700;
    line-height: 18.15px;
    color: #001D3D;
  }

  &__box{
    border: 1px solid #CADAE8;
    border-radius: 10px;
    padding: 1rem;
    min-height: 180px;
    
  }
}

.card-content {
  min-height: 400px;
  display: flex;
  flex-direction: column;

  .table-container {
    flex: 1 1;
    overflow: unset;
    overflow-x: auto;
  }
}

// Forms general classes
.inspectionForm {
  padding: $xMargin $sideMargin;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: $white;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 12px;
  margin: 14px 0;

  &__sectionTitle {
    display: block;
    margin: 20px 0;
    font-weight: 600;
    padding: 0;
    color: $tundora;
  }

  &__input {
    width: 100%;
  }

  &__title {
    color: $slate-gray;
    font-size: 20px;
  }
}

.claimantInfo {
  font-family: 'InterRegular', sans-serif;
  text-align: left;

  &__title {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    color: #3C546E;
  }

  &__casesNumber {
    font-size: 28px;
    font-weight: 700;
    line-height: 34px;
    color:#001D3D;
  }

  &__description {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    color: #A3BED6;
  }

  .circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 5px;

    &__blue{
      background-color: #001D3D;
    }

    &__orange{
      background-color: #F15B2B;
    }
  }
}

.modal-for-cancelOrRestore .MuiTextField-root {
  margin-top: 4px;
}

@media (min-width: $md) {
  .inspection {
    padding: 0;
    box-sizing: border-box;

    &__title {
      display: block;
    }
  }

  // Forms general classes
  .inspectionForm {
    &__title {
      display: block;
    }

    &__row {
      display: flex;
      flex-direction: row;
    }

    &__input {
      margin-right: 10px;
    }

    &__checkboxesForm {
      width: 30%;
    }
  }

  .croquis {
    &__confirmation {
      width: 500px;
      color: $slate-gray;
      line-height: 1.35;
    }

    &__image {
      height: 300px;
      margin-top: 10px;
      margin-bottom: 50px;
      border: 3px #536882 solid;
      border-radius: 10px;
    }
  }
}

@media only screen and (max-width: $tablet) {
  .gridCard {
    &__title {
      margin: 0;
    }
  }

  .eventStatuses {
    justify-content: flex-start;
    overflow-x: scroll;
  }
}

.filterSection {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: white;
  border-bottom: 1px solid #D9D9D9;
  margin-bottom: 10px;
}