@import 'assets/connect/styles/_variables';

.topbar-logo {
  height: 40px;
  margin: 8px;
  width: 74px;
  max-width: 80px;
  margin-right: 0;
}

.topbar1-logo {
  height: 40px;
  margin: 8px;
  width: 74px;
  max-width: 80px;
  margin-right: 0;
}

.MuiButtonBase-root.MuiTabScrollButton-root.MuiTabs-scrollButtons {
  color: $secondary;
}

.incompatible-view {
  display: flex;
  height: 100%;

  &__bodyMsg {
    align-self: center;
    font-size: 1.2rem;
    font-weight: 500;
    text-align: center;

    #deny-icon {
      margin-top: 2rem;
      width: 80px;
    }

    #lblCompatible {
      font-size: 1rem;
      font-weight: 400;
      margin-top: 1rem;
    }

    #browser-icon {
      margin-top: 4rem;
      margin-bottom: 1rem;
      width: 50px;
    }

    #foot-signed {
      font-size: 0.9rem;
      font-weight: 400;
      margin-top: 1rem;
    }

    .app-link {
      cursor: pointer;
      color: blue;
      text-decoration: underline;
    }

    .li-text-custom {
      font-family: Open Sans, sans-serif;
      margin: 0;
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.43;
      color: rgba(0, 0, 0, 0.6);
      display: block;
    }
  }
}

@media (max-width: 340px) {
  h6.MuiTypography-subtitle2 {
    font-size: 75%;
  }
}
