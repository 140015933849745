$app-font: 'Trade Gothic', Helvetica, Arial, sans-serif;

$primary: #de6d19;
$secondary: #586982;
$white: #ffffff;
$black: #000000;
$black-1: #1f2020;
$cadet-blue: #a4b0c1;
$blue-bayoux: #586982;
$boulder: #747474;
$shakespeare: #40a5cb;
$granny: #8da4a7;
$fern: #5db77e;
$alizarin-crimson: #df2f2f;
$dusty-gray: #979797;
$red: #fd0000;
$alto: #d8d8d8;
$aqua-haze: #f1f4f7;
$aqua-haze-light: #f8fafb;
$neptune: #7dc2b0;
$cool-gray: #e2e8ef;
$alabaster: #fafafa;
$cascade: #87a5a8;
$info: #439efb;
$concrete: #f3f3f3;
$calendar: #f0f0f0;
$slate-gray: #6d7b91;
$ghost: #c9cfd6;
$mine-shaft: #333333;
$hawkes-blue: #e9effe;
$tundora: #4d4d4d;
$yellow: #e5d04a;
$purple: #941b80;
$gray-1: #b9c1cc;
$gray-2: #f0f4f7;
$gray-3: #c4c4c4;
$yellow-1: #f4b400;
$green-1: #0f9d58;
$blue-1: #003bf4;

$orange: #F15B2B;
$darkorange: #F8A78D;
$activeOrage: #001D3D;
$grey: #E5E5E5;
$skyBlue: #F9FCFF;

// Orange Colors
$orange-100: #FFF2EE;
$orange-200: #FCCCBD;
$orange-300: #F8A78D;
$orange-400: #F4815C;
$orange-500: #F15B2B;

// Blue Colors
$blue-100: #EEF7FF;
$blue-200: #CADAE8;
$blue-300: #A3BED6;
$blue-400: #586983;
$blue-500: #001D3D;

// Grey Colors
$grey-100: #F5F5F5;
$grey-200: #E5E5E5;

// Alert Colors
$alert-error-100: #FFF6F6;
$alert-error-500: #F44336;
$alert-info-100: #EBF2FF;
$alert-info-500: #2979FF;
$alert-success-100: #ECF8E8;
$alert-success-500: #43A047;
$alert-warn-100: #FFF8EA;
$alert-warn-200: #856404;
$alert-warn-500: #FFB41F;

// Colors with opacity
$primary-op10: rgba(201, 101, 40, 0.1);
$primary-op7: rgba(201, 101, 40, 0.7);
$gray-op5: rgba(147, 147, 147, 0.5);

$font-color: $black;
$font-size: 16px; //minimum size to prevent mobile browsers input zoom.
$padding: 16px;
$padding-lg: calc(#{$padding} * 3);

//Sizes
$xs: 0px;
$sm: 600px;
$md: 960px;
$lg: 1280px;
$xl: 1920px;

//Breackpoints min-width
$mobile: 479px;
$tablet: 1023px;

//Signature component
$light-gray: #e7ebec;
$dark-gray: #979797;
