@import 'assets/connect/styles/_variables';

.filters {
  display: flex;
  margin-bottom: 20px;
  margin-left: -1rem;
  flex-wrap: wrap;

  & > *,
  > .MuiAutocomplete-root {
    margin: 1rem 1rem 0;
  }

  .MuiFormControlLabel-root {
    margin-top: 0;
  }

  &__dropdown {
    margin-left: 20px;
    width: 190px;
  }
}

.MuiAutocomplete-root {
  margin: 0 18px;
}

.MuiInputBase-root.MuiInput-underline:hover,
.MuiInputBase-root.MuiInput-underline:hover,
.MuiInputBase-root.MuiInput-underline::before,
.MuiInputBase-root.MuiInput-underline::after {
  border: none !important; // tried many things, but only this worked.
}
