.claim-sections {
  margin-top: 30px;
  background-color: #f9fcff;
  padding: 25px;
  border-radius: 3px;

  &__header {
    &--title {
      font-size: 19px;
      font-weight: bold;
    }

    &--subtitle {
      font-family: 'InterRegular', sans-serif !important;
      font-size: 12px;
      line-height: 16px;
      font-weight: 400 !important;
      color: #7d92a1;
      text-transform: capitalize !important;
    }
    &--insurance {
      font-family: 'InterRegular', sans-serif !important;
      font-size: 12px;
      line-height: 16px;
      font-weight: 400 !important;
      color: #F15B2B;
      background-color: #FFF2EE;
      margin-right: 8px;
      text-transform: capitalize !important;
    }
    &--adjuster {
      font-family: 'InterRegular', sans-serif !important;
      font-size: 12px;
      line-height: 16px;
      font-weight: 400 !important;
      color: #001D3D;
      background-color: #EEF7FF;
      margin-right: 8px;
      text-transform: capitalize !important;
    }
  }

  &__content-accordion {
    .accordion {
      background-color: #f9fcff;
      box-shadow: none;
    }
  }

  .MuiAccordion-root.Mui-expanded:not(:first-child) {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
  }

  .MuiFormControl-root input {
    background-color: white;
    text-transform: uppercase;
  }

  .typography {
    font-size: 20px;
    line-height: 24px;
  }

  .MuiAccordionSummary-root {
    align-items: center;
    display: flex;
    justify-content: space-between;
    .MuiAccordionSummary-content {
      width: 100%;
      margin: 0;
      padding: 2rem 0 2rem 0;
    }
    .MuiAccordionSummary-expandIcon {
      margin-right: 0;
    }
  }

  .MuiButton-containedSecondary {
    height: 2.5rem;
  }
}

.modal-confirm-title {
  font-family: Connect;
  font-size: 30px;
  font-style: 'normal';
  color: #001d3d;
  text-transform: 'uppercase';
  line-height: 48px;
}

.modal-confirm-input {
  font-family: Connect;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.8px;
}

.css-13xfq8m-MuiTabPanel-root {
  padding: 0;
}

.alertSavedChanges {
  .MuiAlert-message {
    width: 100%;
  }
  .claim-button {
    margin-top: -3px;
    margin-right: 7rem;
  }
  position: fixed;
  bottom: 5rem;
  left: 4.4rem;
  width: 100%;
  z-index: 1;
}

.fix-height-detail {
  margin-bottom: 7rem;
}

.alertError {
  .MuiAlert-message {
    width: 100%;
  }
}

.required-title {
  color: red;
}

.hasError {
  color: #f44336;
}

.hasError::after {
  content: '*';
  color: #f44336;
  margin-left: 4px;
}

.reasonContainer {
  width: '100%';
  padding: 10px 20px 10px 0px;
  display: flex;
}

.reasonLabel {
  height: 35px;
  line-height: 33px;  
}

.reasonSelect {
  height: 35px;
  margin-left: 10px;
  background-color: #ffffff;
  border: 1px solid #87a5a8;
  border-radius: 5px;
  font-size: 1em;
  padding: 0 10px 0 10px;
  color: #5f6667;
}

.reasonSelect:focus {
  outline: none;
}