.custom-table {
  width: 100%;
  border-collapse: collapse;
}

.custom-table th,
.custom-table td {
  padding: 1rem;
  text-align: center;
}

.custom-table th {
    border-bottom: 3px solid #cadae8;
    font-family: Connect;
    font-size: 16px;
    font-weight: 700;
    line-height: 16px;
    color: #001D3D;
    text-transform: uppercase;
}

.custom-table tbody tr.even {
  background-color: #f8fcff;
}

.custom-table tbody tr.odd {
  background-color: #ffffff;
}

.custom-table tbody {
  font-family: Connect;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  color: #586983;
}
