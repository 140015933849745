@import 'assets/connect/styles/_variables';

.reportForm {
  box-sizing: border-box;
  overflow-y: visible;
  height: 100%;
  position: relative;
  
  &__title {
    display: none;
    // color: $slate-gray;
    color: red;
    font-size: 24px;
    margin-top: 0;
  }

  .datepicker__input {
    margin-top: 8px;
  }

  .downloadSection {
    text-align: center;
    margin-top: 6rem;
  }

  .generateSection button {
    margin-top: 25px;
  }

  .MuiAutocomplete-root {
    margin: 0;
  }
}

@media (min-width: $md) {
  .reportForm {
    padding: 0;
    height: 100%;
    box-sizing: border-box;

    &__title {
      display: block;
    }
  }
}

@media (max-width: $md) {
  .reportForm {
    padding: 0;
    height: 100%;
    box-sizing: border-box;

    &__title {
      display: block;
    }

    .downloadSection {
      margin-top: 2rem;
    }

    .generateSection {
      text-align: center;
    }
  }
}