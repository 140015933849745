button.toolbar-button {
  background-color: transparent;
  border: none;
  color: rgba(white, 0.75);
  margin-top: 5px;
  margin-left: 4px;
  margin-right: 4px;
  cursor: pointer;
}
button.toolbar-button:hover {
  color: white;
}

.gallerySelector_btnNotes{
  border:1px solid #696969;
  border-radius: 5px;
  width:85%;
  padding:0 5px 0 5px;
  text-align: center;
  color:#696969;
  margin-top:5px;
  font-weight: bold;
  box-shadow: 1px 1px 4px #e4e4e4;
  cursor:pointer;
}

.gallerySelector_btnNotes:hover{
  background-color:#de6d19;
  color:white;
  border:1px solid #de6d19;
}

.gallerySelector_NotesModal{
  width:500px;
}