.CustomFields {
  background-color: white;
}

.auditable-field {
  .MuiOutlinedInput-notchedOutline {
    border-color: #ffb41f !important;
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #ffb41f !important;
  }
}

.chipLabel {
  min-width: 149px;
  margin-top: 3%;
}

.chipTop {
  margin-top: 1rem;
}
