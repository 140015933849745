@import 'assets/connect/styles/_variables';

$sideMargin: 16px;

$xMargin: 12px;

.card-content {
  min-height: 400px;
  display: flex;
  flex-direction: column;

  .table-container {
    flex: 1 1;
    overflow: unset;
    overflow-x: auto;
  }
}

// Forms general classes
.preInspectForm {
  padding: $xMargin $sideMargin;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__sectionTitle {
    display: block;
    margin: 20px 0;
    font-weight: 600;
    padding: 0;
    color: $tundora;
  }

  &__input {
    width: 100%;
  }

  &__title {
    color: $slate-gray;
    font-size: 20px;
  }
}

.create-preinspection {
  max-width: 650px;
  margin: 0 auto;

  &__header.MuiCardContent-root {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    font: normal normal bold 20px/27px Open Sans;
    color: var(--unnamed-color-6d7b91);
    letter-spacing: 0px;
    color: #6d7b91;
    opacity: 1;
    .step {
      width: 23px;
      height: 23px;
      margin: 10px;
      color: white;
      background: var(--unnamed-color-b9c1cc) 0% 0% no-repeat padding-box;
      background: #b9c1cc 0% 0% no-repeat padding-box;
      padding-right: 1px;
      padding-bottom: 1px;
      border-radius: 15px;
      &.selected {
        background: #586982 0% 0% no-repeat padding-box;
      }
      opacity: 1;
      display: inline-block;
      text-align: center;
      font-size: 16px;
    }
  }

  &__content.MuiCardContent-root {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    color: $gray-1;
    gap: 1rem;
    .cp-textField {
      width: 80%;

      input {
        text-align: center;
        font-size: 1.3rem;
      
      }
      
      input[type="email"]{
        text-transform: lowercase;
      }
    }

    .cp-input {
      background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
      background: #ffffff 0% 0% no-repeat padding-box;
      border: 1px solid #c4c4c4;
      border-radius: 10px;
      opacity: 1;
      margin-top: 20px;
      width: 80%;
      height: 56px;
      padding: 0 10px;
      ::placeholder {
        color: var(--unnamed-color-757575);
        text-align: left;
        font: normal normal normal 16px/22px Open Sans;
        letter-spacing: 0px;
        color: #757575;
        opacity: 1;
        padding-left: 10px;
      }
    }

    .cp-radio {
      padding: 0 1rem;
      width: 100%;
      border-radius: 10px;
      background-color: $aqua-haze;
      display: flex;
      align-items: center;
      user-select: none;
      color: var(--unnamed-color-757575);
      text-align: left;
      font: normal normal normal 14px/19px Open Sans;
      letter-spacing: 0px;
      color: #757575;
      opacity: 1;
      margin-bottom: 10px;
    }

    .cp-divider {
      width: 100%;
      margin: 3rem 0;
    }

    .cp-link {
      padding: 0 1rem;
      width: 90%;
      border-radius: 10px;
      background-color: $aqua-haze;
      display: flex;
      justify-content: space-between;
      align-items: center;
      user-select: none;

      &__button {
        color: black;
      }
    }

  }

  &__footer.MuiCardContent-root {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;    
    color: $gray-1;
    
    .cp-button {
      color: var(--unnamed-color-ffffff);
      text-align: left;
      font: normal normal normal 15px/20px Open Sans;
      letter-spacing: 0px;
      color: #ffffff;
      opacity: 1;
      width: 114px;
      height: 42px;
      background: #586982 0% 0% no-repeat padding-box;
      border-radius: 21px;
      opacity: 1;
      margin-top: 30px;
    }
  }
}

@media (min-width: $md) {
  .inspection {
    padding: 0;
    box-sizing: border-box;

    &__title {
      display: block;
    }
  }

  // Forms general classes
  .preInspectForm {
    background-color: $white;
    margin: 14px 0;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 12px;
    &__title {
      display: block;
    }

    &__row {
      display: flex;
      flex-direction: row;
    }

    &__input {
      margin-right: 10px;
    }
    &__checkboxesForm {
      width: 30%;
    }
  }

  .croquis {
    &__confirmation {
      width: 500px;
      color: $slate-gray;
      line-height: 1.35;
    }
    &__image {
      height: 300px;
      margin-top: 10px;
      margin-bottom: 50px;
      border: 3px #536882 solid;
      border-radius: 10px;
    }
  }
}
