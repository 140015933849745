.subrogation {
  background-color: #f9fcff;

  &__table {
    margin-top: 50px;

    &--link {
      color: #001d3d;
      text-decoration-color: #001d3d;
    }

    &--options {
      padding: 15px;
      display: flex;
      background-color: #f4815c;
      height: 48px;
      font-size: 14px;
      color: white;
      align-items: center;

      span {
        text-transform: none !important;
      }

      &--actions {
        display: flex;
        margin-left: auto;
      }
    }
  }
  &__w500 {
    width: 500px;
  }
  &__data {
    display: flex;
    gap: 5px;

    &--row {
      display: flex;
      gap: 5px;
      margin-top: 10px;
      width: 325px;

      &--input {
        width: 300px;
        font-size: 14px;
      }
    }

    &--title-result {
      color: #dd620d;
      text-align: left;
      font-size: 24px;
      font-weight: bold !important;
      margin-bottom: 0px;
    }
    &--subtitle-result {
      text-transform: capitalize !important;
      color: #dd620d;
      text-align: left;
      font-size: 18px;
      margin-top: 0px;
      margin-bottom: 0px;
    }
    &__large {
    }
  }
  &__required{
    fieldset{
      border-color: #d32f2f;
    }
  }

  &__amount {
    input {
      text-align: right;
    }
    
  }
}
