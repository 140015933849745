.fraud-scan {
    min-width: 270px;
    height: 32px;
    padding: 4px 16px;

    &__title {
        &--label {
            padding-left: 0.5rem;
            font-family: Connect;
            font-size: 14px;
            font-weight: 700;
            line-height: 16px;
            letter-spacing: 0.8px;
        }

        &--span {
            text-transform: none !important;
            font-family: Connect;
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: 0.8px;
        }
    }

}