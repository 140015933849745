@import 'assets/connect/styles/_variables';

.user-picker {
  cursor: default;
  position: absolute;
  background-color: $white;
  border: 1px solid $light-gray;
  border-radius: 5px;
  width: 350px;
  text-align: start;
  padding: 12px;
  margin-top: -65px;
  margin-left: 40px;

  box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.25);

  &::before,
  &::after {
    position: absolute;
    display: block;
    content: '';
    top: 35px;
    border: 10px solid transparent; /*Adjust chevron size*/
  }

  /*Change the four instances of 'top' below to rotate chevron*/
  /*Use (top/right/bottom/left) where you want the back of the chevron to be*/
  &::before {
    left: -20px;
    border-right-color: $light-gray; /*Chevron Color*/
  }
  &::after {
    left: -19px; /*Adjust thickness*/
    border-right-color: white; /*Match chevron background colour*/
  }

  .close {
    margin-top: -5px;
    margin-right: -5px;
    float: right;
    cursor: pointer;
  }

  h5 {
    margin-top: 0px;
    margin-bottom: 10px;
  }

  .MuiAutocomplete-root {
    margin: 0;
  }
}
