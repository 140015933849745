.agreement-detail {
  background-color: #f9fcff;

  &__data {
    display: flex;
    gap: 5px;
    text-align: center;
    flex-wrap: wrap;

    &--title {
      font-size: 12px;
      color: #7d92a1;
      text-align: left;
    }

    &--title-result {
      color: #dd620d;
      text-align: left;
      font-size: 24px;
      font-weight: bold !important;
      margin-bottom: 0px;
    }
    &--subtitle-result {
      text-transform: capitalize !important;
      color: #dd620d;
      text-align: left;
      font-size: 18px;
      margin-top: 0px;
    }
  }

  &__signature {
    width: 25rem;
  }

  &__btn-alert {
    height: auto;
  }
}
