@import 'assets/connect/styles/_variables';

.steps {
  display: flex;
  overflow-x: auto;
  padding: 0 10px;

  &__tab {
    flex-shrink: 0;
    background-color: $ghost;
    margin: 4px;
    padding: 0.6rem;
    text-align: center;
    border-radius: 38px;

    color: $slate-gray;
    font-weight: 600;
    cursor: pointer;

    &--selected {
      background-color: $slate-gray;
      color: $white;
    }
    &--error {
      //background-color: $slate-gray;
      color: #f44336 !important;
    }
  }
}

@media (min-width: $md) {
  .steps {
    background: $ghost;
    border-radius: 38px;
    width: fit-content;
    padding: 0;
    max-width: 100%;

    &__tab {
      margin: 0;
      padding: 18px 4px;
      min-width: 150px;

      &--selected {
        color: $slate-gray;
        background-color: $white;
      }
    }
  }
}
