.subrogation {
  background-color: #f9fcff;
  &__w500 {
    width: 500px;
  }
  &__data {
    display: flex;
    gap: 5px;

    &--row {
      display: flex;
      gap: 5px;
      margin-top: 10px;
      width: 325px;

      &--input {
        width: 300px;
        font-size: 14px;
      }
    }

    &--title-result {
      color: #dd620d;
      text-align: left;
      font-size: 24px;
      font-weight: bold !important;
      margin-bottom: 0px;
    }
    &--subtitle-result {
      text-transform: capitalize !important;
      color: #dd620d;
      text-align: left;
      font-size: 18px;
      margin-top: 0px;
      margin-bottom: 0px;
    }
    &__large {
      fieldset {
        width: 500px;
      }
      
    }
  }
  &__required{
    fieldset{
      border-color: #d32f2f;
    }
  }

  &__amount {
    input {
      text-align: right;
    }
    
  }
}
