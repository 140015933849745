.approval {
  .approval-title {
    font-weight: bold;
    font-size: 1.5em;
  }

  .approval-table{
    width: 100%; 
    overflow: hidden; 
    margin-top: 40px;
  }

  .tableHeader {
    min-width: 100;
    font-weight: bold;
    font-size: 1em;
  }

  .approval-subtitle {
    color: gray;
    font-size: 1em;
  }

  .tablerow{
    cursor:pointer;
  }
}