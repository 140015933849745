.fraud {
  min-width: 500px;
  background-color: #f8fcff;
  padding-bottom: 0.5rem;

  &__alert {
    padding: 4px 16px;
    background: #fff8ea;
  }

  &__title {
    &--label {
      color: #001d3d;
      padding-left: 0.5rem;
      font-family: Connect;
      font-size: 18px;
      font-weight: 1000;
      line-height: 24px;
      letter-spacing: 1.2999999523162842px;
    }

    &--span {
      color: #001d3d;
      text-transform: none !important;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0.5px;
      padding-left: 0.3rem;
    }
  }

  &__content {
    min-width: 656px;
    top: 144px;
    left: 1072px;
    gap: 0px;
    opacity: 0px;
    padding: 24px 16px;
    display: contents;
    position: absolute;

    &--label {
      font-size: 12px;
      font-weight: 700;
      line-height: 16px;
      letter-spacing: 0.800000011920929px;
      color: #586983;
      padding: 1rem;
      padding-bottom: 0;
    }

    &--section {
      gap: 24px;
      display: flex;
      margin: 1rem;
    }

    &--card {
      width: 100%;
      span {
        font-size: 10px;
        font-weight: 200;
        color: #586983;
      }

      .info {
        font-size: 14px;
        font-weight: 200;
        text-align: left;
        color: #586983;
        text-transform: inherit;
        display: flex;
        align-items: flex-start;
        margin-top: 0.5rem;
      }
      .info img {
        margin-right: 8px;
      }

      .count {
        font-size: 16px;
        font-weight: 700;
        line-height: 16px;
        color: #f15b2b;
        margin-left: auto;
      }

      .account {
        display: block;
        margin-top: 1rem;
        text-transform: inherit !important;
      }

      .details {
        display: flex;

        span {
          font-size: 14px;
          font-weight: 200;
          color: #586983;
          margin-left: 0.5rem;
          text-transform: none !important;
        }
      }
    }
  }

  &__cases {
    margin-top: 1rem;
    max-height: 125px;
    overflow-y: auto;
    padding: 0 25px;
    margin-bottom: 0.4rem;
    &--label {
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0.800000011920929px;
      text-align: left;
      color: #001d3d;
    }

    &--p {
      color: #254e70 !important;
      margin: 0px !important;
      font-weight: 200 !important;
      text-transform: none !important;
    }
    &--a {
      color: #254e70;
    }
  }

  img {
    width: 16px;
    height: 16px;
  }
}

a:link {
  text-decoration: none;
  padding-left: 4px;
}
a:hover {
  text-decoration: underline;
}

.label-general {
  font-size: 16px;
  font-weight: 700 !important;
  line-height: 16px;

  span {
    color: inherit;
    font-size: inherit;
    font-weight: inherit !important;
    padding-left: 0.2rem;
  }
}

.label-details {
  font-size: 14px;
  font-weight: 700 !important;
  color: #586983;
  text-transform: inherit !important;

  span {
    color: #f15b2b;
    font-size: inherit;
    font-weight: inherit !important;
    padding-left: 0.2rem;
  }

  a {
    color: #f15b2b;
    font-size: inherit;
    font-weight: inherit !important;
    padding-left: 0.2rem;
  }
}

.dashed-divider {
  border-style: dashed;
  border-color: #a3bed6;
  border-width: 1px;
  margin: 16px 0;
  margin-top: 0;
}

.accordion {
  box-shadow: none;
  display: block;
  padding: 0;
}

.accordion-fraud-details {
  max-height: 400px;
  overflow: auto;
}

.accordion-summary {
  display: flex;
  align-items: flex-start;
}

.accordion-summary .MuiAccordionSummary-content {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.accordion-summary .MuiAccordionSummary-expandIconWrapper {
  align-self: flex-start;
  margin-top: 0.3rem;
  margin-left: auto;
}
