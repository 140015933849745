@import '../../../assets/connect/styles/variables';

.activity-log {
  display: flex;
  flex-direction: column;

  button.transition__button {
    align-self: flex-end;
    margin: 30px; // needed to override Material UI internal classes
    border-radius: 38px;
    text-transform: none;
  }
}

button.add-note {
  margin-bottom: 16px;
}

.observations_head_menu{  
  display: flex;
  margin-right: 30px;
  // position:fixed;

  .selectClasification {
    height: 40px;
    margin-left: 10px;
    background-color: #ffffff;
    border: 1px solid #87a5a8;
    border-radius:5px;
    font-size: 1em;
    padding: 0 10px 0 10px;
    color:#5f6667;
  }

  .selectClasification:focus{
    outline:none;
  }

  .labelClasification {
    height: 40px;
    line-height: 38px;
    margin-left:30px;
  }
}
