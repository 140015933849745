@import 'assets/connect/styles/_variables';

.notification-dot {
  height: 15px;
  width: 15px;
  position: absolute;
  top: 0px;
  right: 5px;
  border-radius: 15px;
  background-color: $red;
}
