@import 'assets/connect/styles/_variables';

.claim {

  p,
  span {
    font-family: 'Connect', sans-serif;
    text-transform: uppercase;
    font-style: normal;
    font-weight: 400;
    display: flex;
    align-items: center;
    letter-spacing: 0.8px;
  }

  padding: 15px;
  background-color: white;

  &__number {
    font-size: 32px;
    font-weight: bold;
  }

  &__row {
    display: flex;
    flex-direction: row;
    align-items: center;

    &--info {
      gap: 15px;
      justify-content: space-between;
    }
  }

  &__select {
    width: 319px;
    //height: 30px;
  }

  &__select-sufix {
    padding: 5px;
    width: 135px;
    border: 1px solid #ebf1f5;
    border-radius: 8px;
    background-color: #ffffff;

    &--label {
      color: #8d8e91 !important;
      font-size: 12px !important;
    }
  }

  &__info-header {
    background-color: #f8f8f8;
    border-radius: 3px;
    padding: 10px;
    margin-left: auto;
    text-align: center;
    font-size: 12px;
    height: 80px;
    color: #54565a;

    span {
      margin-right: 5px;
      color: black;
    }

    a {
      color: #2979ff;
    }
  }

  &__general {
    border: 1px solid #E5E5E5;
    min-height: 80px;
    padding: 20px 10px 20px 20px;
    border-radius: 10px;
    margin-bottom: 1rem;
    box-shadow: none;

    &::before{
      display: none;
    }

    &--label {
      font-size: 18px;
      font-weight: 700 !important;
      line-height: 24px;
      text-align: left;
      color: #000000;
    }

    &--details {
      font-size: 16px;
      font-weight: 700 !important;
      line-height: 22px;
      text-align: left;
      color: #2979FF;
      text-transform: capitalize !important;
      float: right;
    }

    &--icon{
      width: 24px;
      height: 24px;
      margin-right: 8px;
      vertical-align: 'middle'
    }

    .box-content{
      width: 100%;
      flex-shrink: 0;
      gap: 1rem;
    }

    &--accordionDetails{
      padding-bottom: 0px; 
    }

    &--fraud{    
      padding-left: 1rem;      
    }

    &--fraud-expanded{
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
    }
  }

  .caption {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: 0.4px;
    color: #7d92a1;
  }

  .information {
    label {
      display: inline-block;
      padding-right: 0.7rem;
      font-style: normal;
      font-weight: 700;
      font-size: 19px;
      line-height: 21px;
      align-items: center;
      color: #000;
    }

    span {
      display: inline-block;
    }

    div:not(:first-child) {
      padding-top: 20px;
    }

    ul {
      margin-top: -3px;
    }
  }
}

.claim__select {
  position: relative;
}

.claim__select-label {
  position: absolute;
  top: 30%;
  transform: translateY(-50%);
  left: 12px;
  pointer-events: none;
  color: #A3BED6 !important;
  font-size: 12px;
  line-height: 15px;
  text-transform: capitalize;
}

.claim__select-input {
  padding-top: 0.7rem;
  font-size: 14px;
  line-height: 22px;
  font-weight: 700;
  font-family: 'Connect', sans-serif;
  text-transform: capitalize;

  span {
    font-weight: 400;
  }

  &--textList {
    padding-left: 5px;
  }
}

#demo-select-small {
  padding-top: 8px;
  padding-bottom: 8px;
}

@media only screen and (max-width: 600px) {
  .claim {
    &__row {
      display: block;
    }

    &__info-header {
      margin-top: 10px;
      margin-bottom: 10px;
      height: auto;
    }
  }
}