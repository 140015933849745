.create {
  font-family: 'Connect';
  text-transform: uppercase;

  &__title {
    font-size: 36px;
    font-weight: 700;
    line-height: 48px;
    text-align: left;
  }

  &__container {
    margin: 1rem 0;
  }

  &__subtitle {
    font-size: 18px;
    font-weight: 900;
    line-height: 24px;
    letter-spacing: 1.2999999523162842px;
    text-align: left;
  }

  &__section {
    background-color: rgba(255, 250, 249, 1);
    padding: 2rem;
    gap: 32px;
  }

  &__card {
    padding: 2rem;
    gap: 8px;
    background-color: white;
    margin-bottom: 2rem;
    box-shadow: 0px 0px 0px 0px rgba(241, 91, 43, 0.1),
      0px 0px 1px 0px rgba(241, 91, 43, 0.1),
      2px 1px 2px 0px rgba(241, 91, 43, 0.09),
      4px 3px 3px 0px rgba(241, 91, 43, 0.05),
      7px 6px 4px 0px rgba(241, 91, 43, 0.01),
      10px 9px 4px 0px rgba(241, 91, 43, 0);

    &--title {
      font-size: 16px;
      font-weight: 700;
      line-height: 16px;
      letter-spacing: 0.800000011920929px;
      text-align: left;
      color: rgba(88, 105, 131, 1);
      text-transform: uppercase;
    }

    &--subtitle {
      margin-top: 0.5rem;
      font-size: 14px;
      font-weight: 200;
      line-height: 18px;
      letter-spacing: 0.800000011920929px;
      text-align: left;
      color: rgba(88, 105, 131, 1);
    }

    &--fields {
      margin-top: 1.5rem;
    }
  }

  &__content {
    padding: 2rem 0;
    gap: 8px;
    margin-bottom: 2rem;
    display: flex;
    justify-content: space-between;
    width: 100%;

    span {
      color: rgba(241, 91, 43, 1);
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0.7999999523162842px;
      text-align: left;
    }
  }

  &__contentList {
    padding: 2rem;
    padding-top: 0;
    &--section {
      padding: 16px 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      
      label {
        font-family: 'Connect';
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        text-align: left;
        color:rgba(88, 105, 131, 1);
      }
    }

    &--title{
        font-family: 'Connect';
        font-size: 18px;
        font-weight: 700;
        line-height: 20px;
        text-align: left;
        color:rgba(88, 105, 131, 1);
        text-transform: uppercase;
    }
  }
}

.box {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  align-items: center;
}
