@import 'assets/connect/styles/_variables';

$sideMargin: 16px;

$xMargin: 12px;

.settings {
  box-sizing: border-box;
  padding-top: 50px;
  overflow-y: visible;
  height: 100%;
  position: relative;

  &__title {
    display: none;
    color: $slate-gray;
    font-size: 24px;
    margin-top: 0;
  }

  .tabButton {
    background-color: #ffe8da;
    color: #bf5f25;
  }
}

.settingsForm {
  &__select {
    width: 13rem;
  }

  &__input-large {
    width: 27rem;
  }

  &__input-auto {
    width: 100%;
  }

  &__toggle-button.MuiButton-outlinedPrimary {
    background-color: #2695c7;
    border-color: #2695c7;
    color: $white;
    margin: 0 4px;
    min-width: 100px;
    margin-top: 0.6rem;
  }
  &__toggle-button.MuiButton-outlinedPrimary:hover {
    background-color: #1c739a;
    border-color: #2695c7;
    color: $white;
  }
  &__toggle-button--selected.MuiButton-outlinedPrimary,
  &__toggle-button--selected.MuiButton-outlinedPrimary:hover {
    background-color: #1c739a;
    color: $white;
    border-color: #1c739a;
  }
  &__toggle-button.MuiButton-root.Mui-disabled {
    background-color: #d4d3d3;
    color: $white;
    border-color: #d4d3d3;
  }

  &__toggle-button-2.MuiButton-outlinedPrimary {
    background-color: #f9efe9;
    border-color: #f9efe9;
    color: #de6d19;
    margin: 0 4px;
    min-width: 100px;
    margin-top: 0.6rem;
  }
  &__toggle-button-2.MuiButton-outlinedPrimary:hover {
    background-color: #de6d19;
    border-color: #de6d19;
    color: $white;
  }
  &__toggle-button-2--selected.MuiButton-outlinedPrimary,
  &__toggle-button-2--selected.MuiButton-outlinedPrimary:hover {
    background-color: #de6d19;
    color: $white;
    border-color: #de6d19;
  }

  &__toggle-button-3.MuiButton-outlinedPrimary {
    background-color: #e0e0e0;
    border-color: #e0e0e0;
    color: #6e6e6e;
    margin: 0 4px;
    min-width: 100px;
    margin-top: 0.6rem;
  }
  &__toggle-button-3.MuiButton-outlinedPrimary:hover {
    background-color: #cecece;
    border-color: #cecece;
    color: #6e6e6e;
  }
  &__toggle-button-3--selected.MuiButton-outlinedPrimary,
  &__toggle-button-3--selected.MuiButton-outlinedPrimary:hover {
    background-color: #cecece;
    color: #6e6e6e;
    border-color: #cecece;
  }

  &__toggle-button-4.MuiButton-outlinedPrimary {
    background-color: #5b14c7;
    border-color: #5b14c7;
    color: $white;
    margin: 0 4px;
    min-width: 100px;
    margin-top: 0.6rem;
  }
  &__toggle-button-4.MuiButton-outlinedPrimary:hover {
    background-color: #5b14c7;
    border-color: #5b14c7;
    color: $white;
  }
  &__toggle-button-4--selected.MuiButton-outlinedPrimary,
  &__toggle-button-4--selected.MuiButton-outlinedPrimary:hover {
    background-color: #6c1ce4;
    color: $white;
    border-color: #6c1ce4;
  }
  &__toggle-button-4.MuiButton-root.Mui-disabled {
    background-color: #d4d3d3;
    color: $white;
    border-color: #d4d3d3;
  }

  &__toggle-button-5.MuiButton-outlinedPrimary {
    background-color: #df2f2f;
    border-color: #df2f2f;
    color: $white;
    margin: 0 4px;
    min-width: 100px;
    margin-top: 0.6rem;
  }
  &__toggle-button-5.MuiButton-outlinedPrimary:hover {
    background-color: #f9efe9;
    border-color: #df2f2f;
    color: #df2f2f;
  }
  &__toggle-button-5--selected.MuiButton-outlinedPrimary,
  &__toggle-button-5--selected.MuiButton-outlinedPrimary:hover {
    background-color: #f9efe9;
    color: #df2f2f;
    border-color: #df2f2f;
  }
  &__toggle-button-5.MuiButton-root.Mui-disabled {
    background-color: #d4d3d3;
    color: $white;
    border-color: #d4d3d3;
  }

  &__toggle-button-6.MuiButton-outlinedPrimary {
    background-color: #f9efe9;
    border-color: #df2f2f;
    color: #d05f5f;
    margin: 0 4px;
    min-width: 100px;
    margin-top: 0.6rem;
  }
  &__toggle-button-6.MuiButton-outlinedPrimary:hover {
    background-color: #d05f5f;
    border-color: #d05f5f;
    color: $white;
  }
  &__toggle-button-6--selected.MuiButton-outlinedPrimary,
  &__toggle-button-6--selected.MuiButton-outlinedPrimary:hover {
    background-color: #d05f5f;
    color: #d05f5f;
    border-color: #d05f5f;
  }
  &__toggle-button-6.MuiButton-root.Mui-disabled {
    background-color: #d4d3d3;
    color: $white;
    border-color: #d4d3d3;
  }

  .selected-phone-country {
    width: 6rem;
  }
}

#customTable {
  margin: 0rem 1rem;
  width: 97%;
}

.validation--error {
  margin-top: 0.4rem;
}

.modalWindow {
  position: absolute;
  width: 38%;
  height: min-content;
  border: '2px solid #000';
  margin: 12% 33%;

  .modalContainer {
    position: relative;
    outline: 0;

    height: 60vh;
    display: flex;
    justify-content: center;
    flex-direction: column;

    &__closeModal {
      position: absolute;
      top: -10px;
      right: -18px;
      width: 25px;
      height: 24px;
      background-color: #ccc;
      text-align: center;
      padding: 0px;
      border-radius: 20px;
      font-size: 18px;
      bottom: 14px;
      cursor: pointer;
      z-index: 1;
    }

    &__image {
      height: inherit;
      width: inherit;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }

    &__toolbar {
      display: flex;
      flex-direction: row;
      margin-top: 20px;
      justify-content: space-between;

      &__button-group {
        &__button {
          margin: 0 4px;
          min-width: unset;
          border-radius: 7px;
          padding: 16px;
          width: 40px;
          height: 40px;
          background-color: #fff;
          text-align: center;
          box-shadow: -1px 5px 11px -1px black;
          font-size: 13px;
          bottom: 14px;
          cursor: pointer;
          z-index: 1;
        }
      }
    }

    img {
      width: 100%;
    }
  }
}

@media (min-width: $md) {
  .settings {
    padding: 0;
    height: 100%;
    box-sizing: border-box;

    &__title {
      display: block;
    }
  }

  // General classes
  .settingsForm {
    background-color: $white;
    margin: 14px 0;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 12px;
    height: fit-content;
    padding-bottom: 1.4rem;

    &__title {
      display: block;
      padding: $xMargin $sideMargin 0 $sideMargin;
    }

    &__row {
      padding: 0 $sideMargin;
      display: flex;
      flex-direction: row;
      padding-top: 20px;
    }

    &__input {
      margin-right: 10px;
    }
    &__checkboxesForm {
      width: 30%;
    }

    &__tabRow {
      display: flex;
      flex-direction: row;
    }

    .pictures {
      &__selector {
        margin-right: 20px;
        margin-bottom: 20px;
      }
    }
  }

  .commentsField {
    &__description {
      width: 830px;
      margin-bottom: 20px !important;
    }
    &__instructions {
      width: 550px;
    }
    &__observation {
      width: 700px;
    }
  }

  .croquis {
    &__confirmation {
      width: 500px;
      color: $slate-gray;
      line-height: 1.35;
    }
    &__image {
      height: 300px;
      margin-top: 10px;
      margin-bottom: 50px;
      border: 3px #536882 solid;
      border-radius: 10px;
    }
  }
}

@media (max-width: $sm) {
  .modalWindow {
    width: 75%;
    height: min-content;
    margin: 51% 13%;
  }
  .settings-form {
    top: 0px;
    padding: 16px;
    background-color: #fff;
    height: 100vh;
    padding-top: 0;
    position: unset;

    .settings__title {
      display: block;
      padding: 0;
      margin-top: 16px;
      font-size: 20px;
    }
    .steps {
      padding: 0;
      .steps__tab {
        font-size: 14px;
        padding: 12px 0;
      }
    }
    .settingsForm {
      margin-top: 36px;
      padding-top: 0px;
      overflow-y: scroll;

      hr.MuiDivider-root.MuiDivider-fullWidth {
        margin-bottom: 20px !important;
      }

      #continue-btn {
        margin: 30px 0px 0px 0px !important;
      }

      .croquis__diagram {
        width: 100%;
        height: unset;
      }
      .croquis__img {
        width: 100%;
        height: unset;
      }
      .sectionContainer {
        #simple-tabpanel-0,
        #simple-tabpanel-1 {
          .MuiBox-root {
            padding: 0px;
          }
        }
      }
    }
  }
}
