.claimBySteps {
    font-family: 'InterRegular', sans-serif;

    &__information {
        &--step {
            font-size: 0.875rem; /* 14px to 0.875rem */
            font-weight: 500;
            line-height: 1.25; /* 20px to 1.25 */
            letter-spacing: 0px;
            text-align: left;
            color: #3C546E;
        }

        &--percentage {
            font-size: 1.75rem; /* 28px to 1.75rem */
            font-weight: 700;
            line-height: 1.43; /* 40px to 1.43 */
            letter-spacing: 0px;
            text-align: left;
            color: #001D3D;
        }

        &--cases {
            font-size: 0.875rem; /* 14px to 0.875rem */
            font-weight: 400;
            line-height: 1.25; /* 20px to 1.25 */
            letter-spacing: 0px;
            text-align: left;
            color: #A3BED6;
        }
        &--orange{
            color:#F15B2B;
        }
    }
}

@media (max-width: 900px) {
    .claimBySteps {
        &__information {
            &--step {
                font-size: 10px;
            }

            &--percentage {
                font-size: 10px;
            }

            &--cases {
                font-size: 10px;
            }
        }
    }
}
