@import 'assets/connect/styles/_variables';

.gallerySelector {
  width: 120px;
  height: 120px;
  border-radius: 12px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #6d7b91;
  cursor: pointer;
  margin-right: 22px;
  margin-top: 13px;

  &.selected {
    border: 4px solid #2fc957;
  }

  &__fileInput {
    width: 100%;
    height: 100%;

    &:active {
      outline: none;
    }

    &::-webkit-file-upload-button {
      visibility: hidden;
    }

    &::before {
      display: none;
    }
  }

  &__imgPreview {
    width: 100%;
    height: 100%;
    border-radius: 20px;
  }

  &__placeholder {
    display: flex;
    flex-direction: column;
    color: $blue-bayoux;
    font-size: 12px;
    align-items: center;
  }

  &__placeholderImage {
    width: 40px;
    height: 40px;
  }

  &__placeholderText {
    margin-top: 16px;
    text-align: center;
  }

  #upload {
    fill: #6d7b91;
    width: 3rem;
  }

  img {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    object-fit: cover;
  }

  .MuiAccordionDetails-root {
    padding-top: 0px;
  }

  .removeItem {
    position: absolute;
    width: 23px;
    height: 24px;
    background-color: #fff;
    text-align: center;
    padding: 0px;
    border-radius: 20px;
    box-shadow: -1px 5px 11px -1px black;
    font-size: 13px;
    right: -7px;
    top: -7px;
    z-index: 1;
  }

  .insuranceItem{
    position: absolute;
    width: 23px;
    height: 24px;
    background-color: #318EE0;
    text-align: center;
    padding-top: 2px;
    border-radius: 20px;
    box-shadow: -1px 5px 11px -1px black;
    font-size: 13px;
    font-weight: bold;
    right: -7px;
    bottom: -7px;
  }

  .clientItem {
    position: absolute;
    width: 23px;
    height: 24px;
    background-color: cornflowerblue;
    text-align: center;
    padding: 0px;
    border-radius: 20px;
    box-shadow: -1px 5px 11px -1px black;
    font-size: 13px;
    right: -7px;
    bottom: -7px;
    :hover + .tooltip .tooltiptext {
      visibility: visible;
    }
  }

  .tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
    .tooltiptext {
        visibility: hidden;
        width: 120px;
        background-color: black;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 5px 0;
        position: absolute;
        z-index: 1;
     }
  }
}

.customPhotoArea {
  border: 2px solid #f44336;
}

.renderImage {
  // border: none;
  position: relative;
}
.MuiButtonBase-root.tabButton:first-child {
  border-top-left-radius: 10px !important;
}

.gallery-modal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100vh;
  align-items: center;

  .container {
    &:focus {
      outline: none;
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 50vh;
    align-items: center;
    width: 100%;

    .toolbar {
      display: flex;
      flex-direction: row;
      margin-top: 20px;
      justify-content: space-between;
      width: 64%;

      .toolbar__button-group {
        .button-group__button {
          &:disabled {
            background-color: $dark-gray;
          }

          margin: 0 4px;
          min-width: unset;
          border-radius: 7px;
          padding: 16px;
          width: 40px;
          height: 40px;
          background-color: #fff;
          text-align: center;
          box-shadow: -1px 5px 11px -1px black;
          font-size: 13px;
          bottom: 14px;
          cursor: pointer;
          z-index: 1;
        }
      }
    }
  }
}

.image-gallery:focus {
  outline: none;
}
.image-gallery {
  width: 80%;
}

.image-gallery-slides {
  height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: 450px;
  height: 450px;
  width: 100%;
}

.confirm-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: min-content;
  margin: auto;
  padding: 16px;

  .container {
    &:focus {
      outline: none;
    }

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border: 1px solid #fff;
    padding: 36px;
    border-radius: 16px;

    .buttons-container {
      display: flex;
      flex-direction: row;
      Button {
        margin: 0 8px;
      }
    }
  }
}

.waitResponse {
  opacity: 0.1;
}

.activeSpinner {
  display: block;
  position: absolute;
}

.inactiveSpinner {
  display: none;
}

.activeCheck {
  display: block;
  position: absolute;
}

img.activeCheck {
  width: 71%;
  height: 71%;
}

.inactiveCheck {
  display: none;
}
