.dinamic-tab-form {
  h2,
  p,
  label,
  input,
  button,
  li,
  div {
    font-family: 'Connect';
  }
}

.tab-claim {
  font-family: 'Connect';
  font-weight: 700;
}
