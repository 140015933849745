@import 'assets/connect/styles/_variables';

.statusCard {
  color: $white;
  padding: 10px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &:hover {
    opacity: 0.7;
  }

  &--gray {
    background-color: $granny;
  }

  &--orange {
    background-color: $primary;
  }

  &--red {
    background-color: $alizarin-crimson;
  }

  &--blue {
    background-color: $shakespeare;
  }

  &--green {
    background-color: $fern;
  }

  &--yellow {
    background-color: $yellow-1;
  }

  &--ajusteExpress {
    background-color: rgba($purple, 0.75);

    .statusCard__text {
      width: max-content;
    }
  }

  &--alerts {
    background-color: rgba($purple, 0.75);

    .statusCard__text {
      width: max-content;
    }
  }

  &--siniestrar {
    background-color: $yellow;
  }

  &--selected {
    box-shadow: 7px 7px 5px $boulder;
  }

  &__icon {
    margin-right: 10px;
  }

  &__text {
    display: flex;
    align-items: center;
    margin: 0;

    &:not(:last-of-type) {
      margin-bottom: 10px;
    }

    &--title {
      font-family: 'InterRegular', sans-serif;
      font-size: 15px;
      font-weight: 700;
      letter-spacing: 0em;
      height: 18px;
      text-align: left;
      color: #001D3D;
    }

    &--big {
      font-size: 22px;
      height: 25px;
      font-weight: normal;
    }

    &--regular {
      font-family: 'InterRegular', sans-serif;
      font-size: 20px;
      font-weight: 700;
      height: 25px;
      letter-spacing: 0em;
      text-align: left;
    }

    &--light {
      font-weight: normal;
    }

    &--light-notifications {
      max-width: 250px;
      min-height: 28px;
      padding: 0px 2px 0px 8px;
      border-radius: 17px;
      background-color: #EEF7FF;
      border: 0.7px solid #CADAE8;font-family: 'InterRegular', sans-serif;
      font-size: 13px;
      font-weight: 400;
      text-align: left;

      .customSpan{
        color: #F15B2B;
        font-weight: 600;
        padding: 0 4px;
      }
    }
  }

  &__help {
    justify-content: flex-end;
    display: flex;
  }
}