@import 'assets/connect/styles/_variables';

$sideMargin: 16px;
$xMargin: 12px;

.circunstancesForm {
  &__button {
    margin-top: 2%;
    width: min-content;
    align-self: flex-end;
    margin: 30px !important; // needed to override Material UI internal classes
    border-radius: 38px;
    text-transform: none;
  }

  &__rowTitle {
    padding: 10px;
  }

  &__rowSubTitle {
    font-size: 12px;
  }

  &__rowOdd {
    background-color: #ededed;
    &__top {
      padding-top: 10px;
    }
    &__bottom {
      padding-bottom: 10px;
    }
  }

  &__rowEven {
    &__top {
      padding-top: 10px;
    }
    &__bottom {
      padding-bottom: 10px;
    }
  }

  .MuiTypography-alignCenter {
    text-align: center;
  }
}
