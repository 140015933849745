.menu {
  //position: relative;
  width: 432px;
  //height: 429.01px;
  left: 0.74px;
  top: 0.58px;
  background-color: #FFFFFF;
  border: 1px solid #EEF7FF;
  border-radius: 10px;

  &__header{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 432px;
    height: 51px;
    background: #001D3D;
    color: #FFFFFF;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    padding: 14px 16px;
    position: relative;
    border-radius: 5px 5px 0 0;

    p{
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.8px;
      color: #FFFFFF;
      flex: none;
      order: 1;
      flex-grow: 1;
    }
  }

  &__list{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    width: 432px;
    height: 42px;
    background: #FFFFFF;
    flex: none;
    order: 3;
    align-self: stretch;
    flex-grow: 0;
    //border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
}

.item-text{
  margin: 11px 16px;
  width: 400px;
  height: 20px;
  font-style: normal;
  font-weight: 200;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.8px;
  color: #525252;
  flex: none;
  order: 1;
  flex-grow: 1;
}

