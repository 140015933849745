@import 'assets/connect/styles/_variables';

.damage-autodetection {
  .status-cell {
    display: flex;
    justify-content: center;
  }
  .MuiDivider-root.MuiDivider-vertical {
    margin: 0 1rem;
  }

  &__images {
    .image {
      background-color: transparent;
      display: flex;
      flex-direction: column;
      text-align: center;
      justify-content: center;
      align-items: center;
      padding: 0 1rem;
      box-sizing: border-box;
      width: 50%;

      &__title {
        display: block;
        margin-bottom: 0.5rem;
        color: $secondary;
        font-size: 1rem;
        font-weight: 600;
      }

      img {
        border: 1px solid #c4c4c4;
        border-radius: 10px;
        width: 100%;
        height: 250px;
        object-fit: contain;
      }
    }
  }

  &__map {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: center;

    .gmap-container {
      margin-bottom: 1rem;
      flex: 1;
    }

    .gmap-container-header {
      overflow: visible;
    }
  }

  &__detail {
    margin-top: 1rem;
  }

  .maps-button {
    display: inline-block;
    text-decoration: none;
    background-color: $green-1;
    color: white;
    padding: 0.3rem 1rem;

    &:hover {
      background-color: darken($green-1, 3%);
    }
  }

  .pp-container {
    width: 10rem;
    height: 10rem;
    margin: 0 1rem;

    &__total {
      background-color: #de6d19;
      color: #fff;
    }

    &__zone {
      background-color: #fff;
      color: #000;
    }
  }

  .pp-header {
    font-size: 18px;
    text-align: center;
    margin-bottom: 0px;
  }

  .pp-body {
    font-size: 4rem;
    text-align: center;
    margin: 0px;

    label {
      font-size: 2rem;
    }
  }
}

@media screen and (max-width: 1279px) {
  .damage-autodetection {
    &__map {
      min-height: 400px;
    }
  }
}
