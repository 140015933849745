@import 'assets/connect/styles/_variables';

.claims {
  box-sizing: border-box;
  overflow-y: visible;

  &__title-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__title {
      color: $slate-gray;
      font-size: 24px;
      margin-top: 0;
      padding: 0 16px;
    }

    &__payment-log {
      margin-top: 10px;
    }
  }

  .MuiBox-root {
    .title-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .title {
        color: $slate-gray;
        font-size: 20px;
        padding: 0 16px;
        letter-spacing: 0px;
        margin-bottom: 10px;
      }
    }
  }

  .btn-top-options {
    text-align: right;
  }

  &__button,
  #back-btn {
    width: 200px;
    align-self: flex-end;
    margin-top: 0px !important; // needed to override Material UI internal classes
    margin-left: 0px !important; // needed to override Material UI internal classes
    border-radius: 38px;
    text-transform: none;
    float: right;
  }

  &__button,
  #next-status-btn {
    width: 200px;
    align-self: flex-end;
    margin-top: 0px !important; // needed to override Material UI internal classes
    margin-left: 0px !important; // needed to override Material UI internal classes
    border-radius: 38px;
    text-transform: none;
    float: right;
  }

  &__button,
  #add-claim-request {
    width: 150px;
    align-self: flex-end;
    margin-top: 0px !important; // needed to override Material UI internal classes
    margin-left: 0px !important; // needed to override Material UI internal classes
    text-transform: none;
    float: right;
  }

  &__toggle-button-rm.MuiButton-containedPrimary {
    background-color: #f9efe9;
    border-color: #f9efe9;
    color: #000;
    margin: 0 4px;
    min-width: 100px;
  }
  &__toggle-button-rm.MuiButton-containedPrimary:hover {
    background-color: #df2f2f;
    border-color: #df2f2f;
    color: #f9efe9;
  }
  &__toggle-button-rm--selected.MuiButton-containedPrimary,
  &__toggle-button-rm--selected.MuiButton-containedPrimary:hover {
    background-color: #df2f2f;
    border-color: #df2f2f;
    color: #f9efe9;
  }
  &__toggle-button-rm.MuiButton-root.Mui-disabled {
    background-color: #d4d3d3;
    color: $white;
    border-color: #d4d3d3;
  }

  .MuiGrid-item {
    width: 150px;
  }

  .MuiAccordionDetails-root {
    display: flex;
    flex-wrap: wrap;

    .card-contained {
      width: 200px;
      margin-bottom: 17px;
    }

    .isPdf {
      border: 0px;
    }

    .card-img {
      width: 175px;
      height: 175px;

      .MuiCardContent-root {
        padding: 0px;

        img {
          width: 100%;
          height: 135px;
        }
      }

      .MuiCardActions-root {
        padding: 2px 0 0 24%;
      }
    }
  }
}

#img-modal {
  .MuiDialogContent-root {
    width: 500px;
    padding: 0px;

    img {
      width: 100%;
      height: 100%;
      vertical-align: middle;
    }
  }
}

.claimsForm {
  &__row {
    padding-top: 20px;
    .MuiTextField-root {
      width: 100%;
    }
  }
  &__input {
    width: 100%;
  }
  &__toggle-label {
    margin: 0px 0px 4px 5px;
    white-space:normal;
  }
  &__toggle-button.MuiButton-outlinedPrimary {
    background-color: $white;
    border-color: $dusty-gray;
    color: $dusty-gray;
    margin: 0 4px;
    min-width: 100px;
  }
  &__toggle-button.MuiButton-outlinedPrimary:hover {
    background-color: rgba($color: $primary, $alpha: 0.7);
    color: $white;
  }
  &__toggle-button--selected.MuiButton-outlinedPrimary,
  &__toggle-button--selected.MuiButton-outlinedPrimary:hover {
    background-color: $primary;
    color: $white;
    border-color: $primary;
  }
  &__toggle-button.Mui-disabled {
    color: black !important;
  }
  &__toggle-button--selected.Mui-disabled {
    color: #fff !important;
  }

  &__label-for-select {
    background-color: white;
    padding-right: 5%;
    padding-left: 5%;
  }
}

#modal-claim-request {
  .MuiDialogActions-root {
    padding-right: 4%;
  }
}

#content-dialog-claim{
  padding-top:8px;
} 