.documents {
  &__title {
    font-family: 'Connect';
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.045em;
    text-align: left;
    text-transform: uppercase;
  }

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 2rem 0;
  }
  &__search {
    margin: 2rem 0;
  }
}

.templateList {
  &__card {
    margin-top: 5px;
  }
}
.speedDial_button a {
  margin-top: 6px;
  margin-left: 0;
}
.MuiSpeedDial-root button {
  width: 40px;
  height: 40px;
}
