.dialog-title {
  font-family: 'Connect';
  font-size: 36px;
  font-weight: 1000;
  line-height: 40px;
  letter-spacing: 1px;
  text-align: left;
  color: rgba(0, 29, 61, 1);
  text-transform: uppercase;
}

.dialog-subtitle {
    font-family: 'Connect';
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.800000011920929px;
    text-align: left;
    color: rgba(0, 29, 61, 1);
}
