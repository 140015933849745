@import 'assets/connect/styles/_variables';

.note {
  border-bottom: 1px solid $alto;
  padding: 16px 20px;
  font-size: 12px;

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &__info {
      display: flex;
      align-items: center;
      flex-direction: row;

      &--spaceItems {
        justify-content: space-between;
      }
    }
  }

  &__infoText {
    margin: 0 0 0.5rem 10px;

    &:last-of-type {
      margin-bottom: 0;
    }

    &--bold {
      color: $boulder;
      font-weight: 600;
    }

    &--light {
      color: $dusty-gray;
    }
  }

  &__message {
    color: $boulder;
    font-weight: 600;
    margin: 12px 36px;
    max-height: 20px;
    transition: max-height 0.4s ease-out;
    line-height: 1.5;
    overflow-y: hidden;
    line-break: anywhere;

    &--open {
      max-height: 700px;
      transition: max-height 0.5s ease-in;
    }
  }

  &__arrow {
    color: $boulder;
  }

  &__editmodal{

    #confirm-restore-title{
      text-align: center;
      font-weight: bold;
    }

    .selectClasification {
      height: 35px;      
      background-color: #ffffff;
      border: 1px solid #87a5a8;
      border-radius: 5px;
      font-size: 1em;
      padding: 0 10px 0 10px;
      color: #5f6667;
      margin-bottom: 10px;
    }
  }
  
}

