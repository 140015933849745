@import '../../../assets/connect/styles/variables';

.datepicker {
  padding: 0;

  &__container {
    position: relative;
  }

  &__cancelIcon {
    position: absolute;
    top: 8px;
    right: 8px;
    cursor: pointer;
    color: $blue-bayoux;
  }

  &__footer {
    background: $calendar;
    padding: 2px;
    display: flex;
    cursor: pointer;
    justify-content: center;
    
  }

  &__footerButton {
    border-radius: 4px;
    padding: 6px;
    margin: 2px 0 8px;
    &:hover {
      background-color:$dusty-gray;
    }
  }

  &__input{
    color: $blue-bayoux;
    width: 240px;
    height: 38px;
    border-radius: 2px;
    border: 1px solid $alto;
    align-items: center;
    display: flex;
    padding: 0 8px;
    justify-content: space-between;
  }

  &__inputPlaceholder {
    color: $alto;
  }

  &__calendarIcon{
    color: $alto;
  }

  &__inputSeparator {
    margin: 0 6px;
  }

  &__calendar {
    position: relative; 
    background-color: $calendar;

    .react-datepicker__month-container{
      background-color: $calendar;
    }
    .react-datepicker__day--selected,
    .react-datepicker__day--keyboard-selected,
    .react-datepicker__day--in-range {
      background-color: $primary;
      border-color: $primary;
    }

    .react-datepicker__day--in-selecting-range {
      background-color: $primary-op7;
    }
  }
}