@import 'assets/connect/styles/_fonts';
html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
}

textarea {
  resize: none;
  font-family: 'Open Sans', sans-serif;

  &::placeholder {
    font-family: 'Open Sans', sans-serif;
  }
}

button {
  outline: none;
}

#root {
  height: 100vh;
}

.MuiOutlinedInput-root {
  border-radius: 10px !important;
  border-width: 6px !important;
}

.MuiInputBase-input {
  border: none !important;
}

.MuiAutocomplete-input {
  padding: 0 !important;
}

@font-face {
  font-family: 'InterRegular';
  src: local('InterRegular'),
    url('./assets/connect/fonts/Inter-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

input:disabled {
  -webkit-text-fill-color: black !important;
}

.MuiOutlinedInput-input.Mui-disabled {
  -webkit-text-fill-color: black;
}

.MuiPickersToolbarText-root.Mui-selected.MuiTimePickerToolbar-ampmLabel {
  color: #de6d19;
}

.ril__imageNext {
  display: none !important;
}

.ril__imagePrev {
  display: none !important;
}

.networkMessage {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 3000;
  font-weight: 600;
}


:focus-visible {
  outline: none;
}