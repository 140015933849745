.expense {
  background-color: #f9fcff;

  input[type='number'] {
    text-align: right;
  }

  &__data {
    display: flex;
    gap: 5px;
    text-align: center;
    margin-top: 20px;

    &--button-save {
      margin-left: 15px;
    }

    &--description {
      width: 400px;
    }
  }

  &__table {
    margin-top: 50px;

    &--link {
      color: #001d3d;
      text-decoration-color: #001d3d;
    }

    &--options {
      padding: 15px;
      display: flex;
      background-color: #f4815c;
      height: 48px;
      font-size: 14px;
      color: white;
      align-items: center;

      span {
        text-transform: none !important;
      }

      &--actions {
        display: flex;
        margin-left: auto;
      }
    }
  }
}
