@import 'assets/connect/styles/_variables';

.search-error {
  margin: auto;
  padding: 2rem;
  text-align: center;
  color: $boulder;
  max-width: 350px;

  img {
    margin-top: 2rem;
    width: 100%;
  }
}
