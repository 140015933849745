@import 'assets/connect/styles/_variables';

.chip {
  min-width: 7.5rem;
  color: $white;
  background-color: $boulder;
  justify-content: center;
  display: flex;
  border-radius: 0.75rem;
  padding: 0.25rem 0;

  &--gray {
    background-color: $granny;
  }

  &--orange {
    background-color: $primary;
  }

  &--red {
    background-color: $alizarin-crimson;
  }
  &--blue {
    background-color: $shakespeare;
  }
  &--green {
    background-color: $fern;
  }
  &--yellow {
    background-color: $yellow;
  }

  &--compact {
    font-weight: bold;
    padding-right: 10px;
    padding-left: 10px;
    border-radius: 0.75rem;
    min-width: 30px;
  }
}

.chip-status {
  display: flex;
  width: 128px;
  height: 45px;
  padding: 15px 10px 15px 10px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  flex-shrink: 0;
  border-radius: 5px;
  background-color: #ecf8e8;
  color: #43a047;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
}
