@import 'assets/connect/styles/_variables';

.topBar {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  left: 73px;
  background-color: $white;
  padding: 0 20px;
  transition: width 195ms ease-in 0ms;
  z-index: 10;
  border-bottom: 1px solid #D9D9D9;

  &-header,
  &-filters {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 64px;
  }
  &-header > :nth-child(2) {
    margin-left: auto;
    margin-right: 20px;
  }

  &--smaller {
    left: 200px;
    transition: width 225ms ease-out 0ms;
  }

  &__searchInput {
    display: flex;
    align-items: center;

    .filterText {
      min-width: 260px;
      border-radius: 0px 5px 5px 0px;
    }
  }

  &__searchIcon {
    color: $granny;
  }

  &__options {
    display: flex;
    align-items: center;
    color: $granny;
  }

  &__avatar {
    margin: 0 20px;
  }

  &__notificationsDot {
    height: 19px;
    width: 19px;
    position: absolute;
    top: -4px;
    right: -4px;
    border-radius: 16px;
    background-color: $red;
    color: white;
    font-size: 13px;
    padding: 2px;
  }

  &__filterType {
    display: flex;
    align-items: center;
    width: 100%;

    .MuiInputBase-root {
      border-radius: 6px;
      min-width: 125px;
    }

    .MuiSelect-select {
      height: 0.7rem;
      width: 6rem;
      padding: 0.7rem;
      background-color: #E5E5E5;
      border-radius: 6px 0 0 6px;
      min-width: 125px;
      font-family: 'Connect', sans-serif;
      font-size: 14px;
      font-weight: 200;
    }

    .MuiSelect-select:focus {
      background-color: #E5E5E5;
      border-radius: 5px 0 5px 0;
    }

    .MuiFilledInput-underline:before {
      border-bottom: 0px;
    }

    .MuiFilledInput-underline:hover:not(.Mui-disabled):before {
      border-bottom: 0px;
    }
  }
}

.header-title{
  font-family: 'ConnectItalic', sans-serif;
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
  color: #001D3D;
  width: 100%;
  padding-left: 15px;
}

@media only screen and (max-width: $tablet) {
  .topBar {
    left: 0;
  }
  .header-title{
    display: none;
  }
}

@media only screen and (max-width: $mobile) {
  .topBar {
    &__searchInput {
      .filterText {
        min-width: 0;
        width: auto;
      }
    }
  }
  .header-title{
    display: none;
  }
}
