@import 'assets/connect/styles/_variables';

//Block 
.validation {

  //Elements
  &__label {
    font-weight: bold;
    font-size: 1rem;
  }
  
  //Modifiers
  &--info {
    color: $info;
  }

  &--warning {
    color: $primary;
  }

  &--error {
    color: $red;
  }
}
