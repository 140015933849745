@import 'assets/connect/styles/_variables';

$sideMargin: 16px;

$xMargin: 12px;

.profileSettings {
  box-sizing: border-box;
  padding-top: 50px;
  overflow-y: visible;
  height: 100%;
  position: relative;

  &__title {
    color: $slate-gray;
    font-size: 24px;
    margin-top: 0;
  }
}

@media (min-width: $md) {
  .profileSettings {
    padding: 0;
    height: 100%;
    box-sizing: border-box;

    &__title {
      display: block;
    }
  }

  // General classes
  .profileSettingsContainer {
    background-color: $white;
    margin: 14px 0;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 12px;
    height: fit-content;
    padding: 1.4rem;

    &__title {
      display: block;
      padding: $xMargin $sideMargin 0 $sideMargin;
    }

    &__row {
      padding: 0 $sideMargin;
      display: flex;
      flex-direction: row;
      padding-top: 20px;
    }

    &__input {
      margin-right: 10px;
    }
  }
}
