@import 'assets/connect/styles/_variables';

.avatar {
  border: 1px solid $dusty-gray;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  &--xsmall {
    height: 28px;
    width: 28px;
    border-radius: 90px;
    padding: 4px;
    text-align: center;
    span {
      pointer-events: inherit;
      font-size: 100%;
    }
  }

  &--small {
    height: 36px;
    width: 36px;
    max-height: 36px;
    border-radius: 90px;
    span {
      pointer-events: inherit;
      font-size: 120%;
    }
  }

  &--medium {
    height: 41px;
    width: 41px;
    border-radius: 41px;
  }
  &--large {
    height: 50px;
    width: 50px;
    border-radius: 41px;
  }
}
