@import 'assets/connect/styles/_variables';

$sideMargin: 16px;

$xMargin: 12px;

.gridCard {
  border-radius: 8px;
  border: 1px solid rgba(224, 224, 224, 1);
  position: relative;
  min-height: 60vh;

  &__title {
    color: $primary;
    font-size: 20px;
    font-weight: normal;
  }
}

.titleFont {
  color: $primary;
}

.eventStatuses {
  display: flex;
  margin-bottom: 20px;
  justify-content: flex-end;

  &__card {
    &--notif {
      background-color: $neptune;
      cursor: auto;
      opacity: 1;
    }

    &--small {
      flex: 0.4;
    }

    &--md {
      flex: 1;
    }
    &--big {
      flex: 2;
    }
  }

  & > :not(:last-child) {
    margin-right: 15px;
  }

  & > :first-child {
    &:hover {
      opacity: inherit;
    }
  }
}

.card-content {
  min-height: 400px;
  display: flex;
  flex-direction: column;

  .table-container {
    flex: 1 1;
    overflow: unset;
    overflow-x: auto;
  }
}

// Forms general classes
.inspectionForm {
  padding: $xMargin $sideMargin;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: $white;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 12px;
  margin: 14px 0;

  &__sectionTitle {
    display: block;
    margin: 20px 0;
    font-weight: 600;
    padding: 0;
    color: $tundora;
  }

  &__input {
    width: 100%;
  }

  &__title {
    color: $slate-gray;
    font-size: 20px;
  }
}

.modal-for-cancelOrRestore .MuiTextField-root {
  margin-top: 4px;
}

@media (min-width: $md) {
  .inspection {
    padding: 0;
    box-sizing: border-box;

    &__title {
      display: block;
    }
  }

  // Forms general classes
  .inspectionForm {
    &__title {
      display: block;
    }

    &__row {
      display: flex;
      flex-direction: row;
    }

    &__input {
      margin-right: 10px;
    }
    &__checkboxesForm {
      width: 30%;
    }
  }

  .croquis {
    &__confirmation {
      width: 500px;
      color: $slate-gray;
      line-height: 1.35;
    }
    &__image {
      height: 300px;
      margin-top: 10px;
      margin-bottom: 50px;
      border: 3px #536882 solid;
      border-radius: 10px;
    }
  }
}

@media only screen and (max-width: $tablet) {
  .gridCard {
    &__title {
      margin: 0;
    }
  }

  .eventStatuses {
    justify-content: flex-start;
    overflow-x: scroll;
  }
}
