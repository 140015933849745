.alert {
  width: 30px;
  &__1 {
    background-color: #ffb41f;
  }

  &__2 {
    background-color: #f44336;
  }

  &__icon {
    padding: 25px 5px 20px 5px;

    img {
      height: 15px;
    }
  }
}

.tooltip {
  position: relative;
  display: inline-block;

  .tooltiptext {
    visibility: hidden;
    position: absolute;
    top: -10px;
    display: flex;
    width: 267px;
    height: 53px;
    padding: 10px 2.141px 0px 15px;
    align-items: center;
    border-radius: 10px;
    background-color: #fff6f6;

    font-family: 'Connect';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.15px;
    z-index: 1;
    gap: 15px;
  }
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
