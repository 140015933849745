@import 'assets/connect/styles/_variables';

$sideMargin: 16px;

$xMargin: 12px;

.pre-inspection {
  box-sizing: border-box;
  overflow-y: visible;

  &__title-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &__data,
    &__title {
      display: none;
      color: $slate-gray;
      font-size: 24px;
      margin-top: 0;
      padding: 0 16px;
    }

    &__data {
      span {
        font-weight: normal;
      }
    }
  }

  &__toggle-button.MuiButton-containedPrimary {
    float: right;
    width: 20%;
    margin: 0.6rem 4px 0;
  }

  .tabButton {
    background-color: #ffe8da;
    color: #bf5f25;
  }

  &__request-analysis-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 75px;
  }
}

.modalWindow {
  position: absolute;
  width: 38%;
  height: min-content;
  border: '2px solid #000';
  margin: 12% 33%;

  .modalContainer {
    position: relative;
    outline: 0;

    height: 60vh;
    display: flex;
    justify-content: center;
    flex-direction: column;

    &__closeModal {
      position: absolute;
      top: -10px;
      right: -18px;
      width: 25px;
      height: 24px;
      background-color: #ccc;
      text-align: center;
      padding: 0px;
      border-radius: 20px;
      font-size: 18px;
      bottom: 14px;
      cursor: pointer;
      z-index: 1;
    }

    &__image {
      height: inherit;
      width: inherit;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }

    &__toolbar {
      display: flex;
      flex-direction: row;
      margin-top: 20px;
      justify-content: space-between;

      &__button-group {
        &__button {
          margin: 0 4px;
          min-width: unset;
          border-radius: 7px;
          padding: 16px;
          width: 40px;
          height: 40px;
          background-color: #fff;
          text-align: center;
          box-shadow: -1px 5px 11px -1px black;
          font-size: 13px;
          bottom: 14px;
          cursor: pointer;
          z-index: 1;
        }
      }
    }

    img {
      width: 100%;
    }
  }
}

@media (min-width: $md) {
  .pre-inspection {
    box-sizing: border-box;
    &__title-container {
      &__data,
      &__title {
        display: block;
      }
    }
  }

  // Forms general classes
  .pre-inspectionForm {
    background-color: $white;
    margin: 14px 0;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 12px;

    &__title {
      display: block;
      padding: $xMargin $sideMargin 0 $sideMargin;
    }

    &__row {
      padding: 0 $sideMargin;
      display: flex;
      flex-direction: row;
      padding-top: 20px;
    }

    &__input {
      margin-right: 10px;
    }
    &__checkboxesForm {
      width: 30%;
    }

    &__tabRow {
      display: flex;
      flex-direction: row;
    }

    .pictures {
      &__selector {
        margin-right: 20px;
        margin-bottom: 20px;
      }
    }
  }

  #scroll-to-top {
    display: none;
  }

  .commentsField {
    &__description {
      width: 830px;
      margin-bottom: 20px !important;
    }
    &__instructions {
      width: 550px;
    }
    &__observation {
      width: 700px;
    }
  }

  .croquis {
    &__confirmation {
      width: 500px;
      color: $slate-gray;
      line-height: 1.35;
    }
    &__image {
      height: 300px;
      margin-top: 10px;
      margin-bottom: 50px;
      border: 3px #536882 solid;
      border-radius: 10px;
    }
  }
}

@media (max-width: $sm) {
  .modalWindow {
    width: 75%;
    height: min-content;
    margin: 51% 13%;
  }
  .pre-inspector-form {
    top: 0px;
    padding: 0;
    background-color: #fff;
    height: max-content;
    position: unset;
    display: flex;
    flex-direction: column;

    .inspection__title-container {
      flex-direction: column;

      .inspection__title-container__data {
        display: block;
        margin: 0;
        padding: 0;
        padding-bottom: 4px;
        font-size: 14px;
      }
      .inspection__title-container__title {
        display: block;
        padding: 0;
        margin-top: 16px;
        font-size: 20px;
      }
    }
    .steps {
      padding: 0;
      .steps__tab {
        font-size: 14px;
        padding: 12px 0;
      }
    }
    .inspectionForm {
      margin-top: 0px;
      padding: 16px;
      overflow-y: unset;
      position: unset;

      hr.MuiDivider-root.MuiDivider-fullWidth {
        margin-bottom: 20px !important;
      }

      #document-btn {
        width: 100%;
        margin: 30px 0px 0px 0px !important;
      }

      #continue-btn {
        width: 100%;
        margin: 30px 0px 0px 0px !important;
      }

      #back-btn {
        width: 100%;
        margin: 30px 0px 0px 0px !important;
      }

      .croquis__diagram {
        width: 100%;
        height: unset;
      }
      .croquis__img {
        width: 100%;
        height: unset;
      }
      .sectionContainer {
        #simple-tabpanel-0,
        #simple-tabpanel-1 {
          .MuiBox-root {
            padding: 0px;
          }
        }
      }
    }
  }
}

#scroll-to-top {
  &.show {
    display: block;
  }

  display: none;
  position: fixed;
  bottom: 83px;
  right: 14px;
  width: 44px;
  height: 44px;
  min-width: unset;
  padding: 10px;
}
