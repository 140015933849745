@import 'assets/connect/styles/_variables';

.tag-popper {
  background-color: $white;
  border: 1px solid $light-gray;
  box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 0.6rem;
  width: 18rem;

  &__header {
    display: flex;
    justify-content: space-between;

    // font-size: 0.9rem;
    color: $boulder;

    h5 {
      margin: 0 0 10px;
    }

    .MuiIconButton-root {
      padding: 0;
      font-size: 0.5rem;
      border: 2px solid currentColor;
    }
  }
}
