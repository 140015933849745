.claim-estimated {
  background-color: #f9fcff;

  input {
    text-align: right;

    &:disabled {
      color: rgba(0, 0, 0, 0.38) !important;
      -webkit-text-fill-color: rgba(0, 0, 0, 0.4) !important;
      caret-color: transparent;
    }
  }

  &__footer {
    text-align: center;
    margin-top: 35px;
  }

  &__content {
    display: flex;
    gap: 25px;
  }

  &__data {
    display: flex;
    gap: 5px;
    text-align: center;

    &--title {
      font-size: 20px;
      text-transform: uppercase;
      color: #001d3d;
      text-align: left;
      font-weight: 400;
      margin-left: 15px;
    }

    &--row {
      display: flex;
      gap: 5px;
      margin-top: 10px;
      width: 325px;

      &--input {
        width: 300px;
        font-size: 14px;
      }
    }

    &--icon {
      font-size: 14px;
      text-align: center;
      font-weight: 400;
      color: #000000;
      font-family: 'InterRegular', sans-serif;
    }

    &--desc {
      display: flex;
      gap: 4px;
      margin-top: 10px;

      &--porc {
        width: 100px;
      }

      &--amount {
        width: 200px;
      }
    }
  }

  &__table {
    margin-top: 50px;

    &--link {
      color: #001d3d;
      text-decoration-color: #001d3d;
    }

    &--options {
      padding: 15px;
      display: flex;
      background-color: #f4815c;
      height: 48px;
      font-size: 14px;
      color: white;
      align-items: center;

      span {
        text-transform: none !important;
      }

      &--actions {
        display: flex;
        margin-left: auto;
      }
    }
  }
}
