@font-face {
  font-family: 'InterBlack';
  src: url('/assets/connect/fonts/Inter-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'InterRegular';
  src: url('/assets/connect/fonts/Inter-Regular.ttf') format('truetype');
}

.modal-overlay {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index:5;
  }
  
  .modal-content {
    background-color: white;
    border: 1px solid #ccc;
    padding: 20px;    
    width: 400px;
    text-align: justify;
    z-index:5;
    font-family: 'InterBlack', sans-serif;

    .modal-content-title{
      font-weight: bold;
      font-size: 2em;
      letter-spacing: -1.5px;
      color: #001D3D;
          
    }

    .modal-content-subtitle{
      font-weight: bold;
      font-size: 0.6em;
      color: #001D3D;
    }

    .progress-bar-container {
        width: 100%;
        height: 3px;
        background-color: #eee;
        border-radius: 5px;
        overflow: hidden;
        margin-top: 10px;
        margin-bottom: 10px;
      }
      
      .progress-bar {
        height: 100%;
        background-color: #ec7328;
        transition: width 0.5s;
      }

      .progress-bar-label{
        color:#777777;
        font-size: 0.8em;
        font-family: 'InterRegular', sans-serif;
      }


  }
  
  
  