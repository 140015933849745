@import 'assets/connect/styles/_variables';

.layout {
  display: flex;
  height: 100vh;

  .MuiPaper-root.MuiDrawer-paper {
    z-index: 999; // interferes with photo galley.
  }

  &__children {
    margin-top: 64px;
    padding: 50px 25px;
    top: 64px;
    background-color: $white;
    width: 100%;
    height: calc(100vh - 64px);
    box-sizing: border-box;
    overflow: auto;
  }
}

#validation-message {
  max-height: 30rem;
  overflow: auto;
}

@media only screen and (max-width: $tablet) {
  .layout {
    &__children {
      padding: 2rem 25px;
    }
  }
}

@media only screen and (max-width: $mobile) {
  .layout {
    &__children {
      margin-top: 128px;
    }
  }
}
