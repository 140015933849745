@import 'assets/connect/styles/_variables';

audio {
  width: 50%;
}

.label-story {
  margin-top: 16px;
  margin-bottom: 16px;
  font-weight: bold;
  color: $primary;
  display: inline-table;
}

.warn-icon {
  margin-bottom: -5px;
  margin-left: 5px;
}

.icon-info {
  padding: 10px;
  color: $primary;
}

.text-info {
  padding-top: 16px;
  font-size: 12px;
  color: $primary;
  font-weight: bold;
}
