$sideMargin: 16px;
$xMargin: 12px;

.eventStateContainer {
  padding: $xMargin $sideMargin;
}

.MuiButtonBase-root.tabButton:first-child {
  border-top-left-radius: 0px !important;
}
