@import 'assets/connect/styles/_variables';

.alerts {
  .MuiButtonBase-root.MuiToggleButton-root {
    padding: 0.2rem 0.8rem;
    background-color: white;
    color: $black-1;
    border-color: $gray-3;
    text-transform: none;

    &.Mui-selected {
      background-color: white;
      border-color: $green-1;
      color: $green-1;
    }
  }
}

.MuiSvgIcon-root {
  &.warn {
    color: $yellow-1;
  }
  &.info {
    color: $blue-1;
  }
}
