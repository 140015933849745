@import 'assets/connect/styles/_variables';

.info-table {
  &.MuiTableContainer-root {
    box-sizing: border-box;
    width: 100%;
  }

  .MuiTableCell-root {
    border: none;

    &.MuiTableCell-head {
      color: $slate-gray;
    }
  }
  padding: 1rem 2rem;

  .MuiTableBody-root {
    .MuiTableRow-root:nth-of-type(odd) {
      background-color: $gray-2;
    }
  }
}
