@import 'assets/connect/styles/_variables';

// This is going to change when we fragment the views into components and it will follow the BEM methodology
.root {
  height: 100vh;
}

.loginForm {
  justify-content: center;
}

.paper {
  margin: 4rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
  margin: 0.5rem 0 2.5rem;
  height: 18vh;
  width: 30vh;
  background-color: transparent;
}

.imageLogo {
  width: 100%;
  height: 100%;
}

.title {
  color: $primary;
  letter-spacing: -0.2rem;
}

.subtitle {
  color: $cool-gray;
}

.form {
  width: 100%; // Fix IE 11 issue.
  margin-top: 0.5rem;
}

.passwordRestoreLabel {
  margin: 0.5rem 0 0;
}

.image {
  background-color: $alabaster;
  background-size: cover;
  background-position: center;
}

.mfaInput input {
  font-size: 23px;
  text-align: center;
}
