.configParams {
  .damageLabel {
    margin-top: 4px;
  }
  .damageSlider {
    width: 25rem;
    margin-left: 15px;
  }
  .saveConfigBtn {
    padding: 15px;
    float: right;
  }
}
