.claim-button {
  color: white;
  text-transform: uppercase;
  height: 55px;
  cursor: pointer;

  &__outline {
    color: rgb(88, 105, 130);
  }

  &__outline-orange {
    color: #de6d19;
  }
}
