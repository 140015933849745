.drawer {
  &__sicLogo {
    width: 44px;
    height: 44px;
    &--large {
      width: unset;
      height: 44px;
    }
  }

  &__tabsContainer {
    height: inherit;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  &__sicLogoCar{
    position: sticky;
    width: 50px;
    height: 50px;
    top: 181.1298828125px;
    left: 10.2666015625px;
  }

  .sub-section{
    height: 66.658203125px;
    border-radius: 0px 2px 2px 0px;
    margin-top: 5rem;
    padding-bottom: 7px;
    background: linear-gradient(0deg, #F15B2B, #F15B2B);
  }

  &__ListContent{
    display: flex;
    flex-direction: column;
    height: 100%;

    .top{
      flex: 1;
    }
    .bottom{
      margin-top: auto;
    }
  }
}
.MuiList-root{
  padding-top: 0px;
}