.claim-steps {
  &__container{
    display: flex;
    align-items: flex-start;
    gap: 16px;
  }

  &__step {
    font-family: 'Connect', sans-serif;
    text-transform: none !important;
    border-radius: 28px !important;
    border: 1px solid !important;
    margin-right: 15px !important;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.8px;
    color: #3C546E;
    
    &--disabled {
      background-color: #C6C7C8;
      color: #FFFFFF !important;
    }
    &--required {
      color: #F44336;
  
      &[aria-selected='true'] {
        background-color: #F44336 !important;
        color: white;
      }
    }
  }

  .claim-steps__current-state {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
    gap: 5px;
    //min-width: 288px;
    height: 54px;
    left: 1585px;
    background: #ECF8E8;
    border-radius: 5px;
    margin-bottom: 1rem;

    label{
      //width: 196px;
      //height: 23px;
      word-wrap: break-word;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      letter-spacing: 0.8px;
      color: #43A047;
      flex:auto;
      order: 0;
      flex-grow: 0;
    }
  }

  .claim-steps__approvalbuttons{        
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
    gap: 5px;
    //min-width: 288px;
    height: 54px;
    left: 1585px;
    // background: #ECF8E8;
    border-radius: 5px;
    margin-bottom: 1rem;
    

    Button{
      //width: 196px;
      //height: 23px;
      border : 1px solid #DE6D19;
      word-wrap: break-word;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      letter-spacing: 0.8px;
      color: #DE6D19;      
      flex:auto;
      order: 0;
      flex-grow: 0;
      cursor:pointer;
    }

    Button:hover{
      background-color: #DE6D19;      
      color: white;      
    }
  }

  &__boxlabel {
    margin: 0 0 1rem;
    height: 32px;
    width: 120.5458984375px;
    left: 117px;
    top: 276.3828125px;
    display: flex;
    align-items: center;

    label {
      font-family: 'Connect', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      align-items: center;
      display: flex;
      letter-spacing: 0.045em;
      text-transform: uppercase;
    }
  }

  [aria-selected='true'] {
    background-color: #3C546E;
    color: white;
  }

  .MuiTab-wrapper {
    width: 100%;
    display: inline-flex;
    align-items: center;
    flex-direction: row;
    gap: 10px;

    img {
      margin-bottom: 0 !important;
      height: 20px;
    }
  }

  .MuiTab-labelIcon {
    min-height: 50px;
    img {
      margin-bottom: 0 !important;
      height: 20px;
    }
  }

  
}