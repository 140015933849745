//Block
.loader {
    position: fixed; /* Sit on top of the page content */
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.7); /* background with opacity */
    z-index: 3; /* Specify a stack order in case you're using a different order for other elements */
    cursor: pointer; /* Add a pointer on hover */

    //Elements
    &__icon {
      position: absolute;
      top: 50%; left: 50%;
      transform: translate(-50%, -50%);
    }

    //Modifiers
    &--on {
      display: block; /* Active */
    }

    &--off {
      display: none; /* Hidden by default */
    }
}
