@import 'assets/connect/styles/_variables';

.notification-dot {
  height: 15px;
  width: 15px;
  position: absolute;
  top: 0px;
  right: 5px;
  border-radius: 15px;
  background-color: $red;
}

.MuiButtonBase-root.MuiChip-root {
  &.event {
    &__tag {
      color: white;
      background-color: $yellow-1;

      &--empty {
        background-color: $secondary;
      }
    }
  }
}

.user-agent {
  display: flex;
  align-items: center;
  justify-content: center;
}

.status-mobile {
  width: 1rem;
  height: 1rem;
  background-color: $gray-1;
  border-radius: 50%;
  display: inline-block;

  &--gray {
    background-color: $granny;
  }

  &--orange {
    background-color: $primary;
  }

  &--red {
    background-color: $alizarin-crimson;
  }
  &--blue {
    background-color: $shakespeare;
  }
  &--green {
    background-color: $fern;
  }
  &--yellow {
    background-color: $yellow;
  }
}
