@import 'assets/connect/styles/_variables';

.filters {
  display: flex;
  margin-bottom: 20px;

  &__dropdown {
    margin-left: 20px;
    width: 190px;
  }
}

.MuiAutocomplete-root {
  margin: 0 18px;
}

.MuiInputBase-root.MuiInput-underline:hover,
.MuiInputBase-root.MuiInput-underline:hover,
.MuiInputBase-root.MuiInput-underline::before,
.MuiInputBase-root.MuiInput-underline::after {
  border: none !important; // tried many things, but only this worked.
}
