@import 'assets/connect/styles/_variables';

.claim-step-info .box-content label {
  top:0%;
  left: 3.06%;
  font-weight: normal;
  font-size: 17px;
}

.remindersModal {
  width: 0px;
  height: 400px;
  background-color: #ffffff;
  position: fixed;
  bottom: 0;
  right: 0px;
  z-index: 5;
  box-shadow: 0 2px 9px 0 rgba(147, 147, 147, 0.5);
  border-radius: 4px;
  transition: width 0.7s;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  padding: 20px 15px 10px 15px;
  visibility: visible;
  
  &_Header{
    border-bottom: 1px solid #b0b7b8;
  }

  .selectTime {
    height: 40px;
    background-color: #ffffff;
    border: 1px solid #b0b7b8;
    border-radius: 10px;
    font-size: 1em;
    padding: 0 10px 0 10px;
    color: #5f6667;
    width: 100%;
  }

  .selectTime:focus {
    outline: none;
  }

  @media screen and (max-width: 2500px) and (min-width: 601px) {
    width: 40%;
  }

  @media screen and (max-width: 600px) and (min-width: 100px) {
    width: 100%;
  }

  &--hide {
    width: 0;
    visibility: hidden;
  }

  &__addNote {
    background-color: #d0dfe0;
    padding: 16px 20px;

    :first-child {
      display: flex;
    }
  }

  &__textArea {
    flex: 1;
    box-sizing: border-box;
    margin-left: 10px;
    padding: 15px;
    border: 1px solid $cascade;
    border-bottom: none;

    &::placeholder {
      color: $boulder;
      font-weight: 600;
      margin-left: 36px;
    }

    &:focus {
      outline: none;
    }

    &--inspector {
      border: 1px solid $cascade;
    }


  }

  &__addButton {
    display: flex;
    justify-content: flex-end;
    margin-left: 10px;
    border: 1px solid $cascade;
    border-top: none;
    background-color: $white;
    padding: 8px;
  }

  &__lblError{
    color:red;
    font-size: 14px;    
    margin-right: 15px;
    margin-left: 15px;
  }
}



