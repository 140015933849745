@import 'assets/connect/styles/_variables';

.name-modal {
  position: fixed;
  background-color: $white;
  width: 300px;
  right: 0;
  z-index: 1000;
  box-shadow: 0 2px 9px 0 $gray-op5;
  border-radius: 8px;
  transition: all 0.7s;
  box-sizing: border-box;
  opacity: 1;

  &--hide {
    width: 0;
    opacity: 0;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__grid {
    padding: 1rem;
    display: flex;
    flex-direction: column;
  }
}

.notesModal {
  &.grid {
    z-index: 1000;
    height: 90vh;
    top: 64px;
    position: fixed;
  }

  &--inspector.grid {
    top: unset;
    bottom: 0;
  }

  @media screen and (max-width: 2500px) and (min-width: 601px) {
    width: 40%;
  }

  @media screen and (max-width: 600px) and (min-width: 100px) {
    width: 100%;
  }

  background-color: #ffffff;
  position: fixed;
  bottom: 0;
  right: 0px;
  z-index: 5;
  box-shadow: 0 2px 9px 0 rgba(147, 147, 147, 0.5);
  border-radius: 4px;
  transition: width 0.7s;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  width: 300px;
  margin-right: 20px;
  /*height: 50%;
  background-color: $white;
  position: absolute;
  right: 0px;
  z-index: 5;
  box-shadow: 0 2px 9px 0 $gray-op5;
  border-radius: 4px;
  transition: width 0.7s;
  justify-content: space-between;
  display: flex;
  flex-direction: column;*/

  &--hide {
    width: 0px;
  }

  &__title {
    display: flex;
    padding: 10px 14px 20px;
    border-bottom: 1px solid $alto;
    align-items: center;
    justify-content: space-between;
    color: $blue-bayoux;
    position: sticky;
    z-index: 1;
    top: 0;
    background-color: $white;
  }

  &__notes {
    overflow-x: scroll;
    height: 100%;
  }

  &__addNote {
    background-color: $aqua-haze;
    padding: 16px 20px;

    :first-child {
      display: flex;
    }
  }

  &__textArea {
    flex: 1;
    box-sizing: border-box;
    margin-left: 10px;
    padding: 15px;
    border: 1px solid $cascade;
    border-bottom: none;

    &::placeholder {
      color: $boulder;
      font-weight: 600;
      margin-left: 36px;
    }

    &:focus {
      outline: none;
    }

    &--inspector {
      border: 1px solid $cascade;
    }


  }

  &__addButton {
    display: flex;
    justify-content: flex-end;
    margin-left: 46px;
    border: 1px solid $cascade;
    border-top: none;
    background-color: $white;
    padding: 8px;
  }

  &__inspector-addButton {
    &.MuiButtonBase-root.MuiIconButton-root {
      margin-left: 8px;
      padding: 1rem;
      align-self: center;
      color: white;
      background-color: $primary;
      border-radius: 10px;
    }
  }

  .notesModal__clasification {
    background-color: #f1f4f7;
    width: '100%';
    padding: 10px 20px 10px 20px;
    display: flex;

    .selectClasification {
      height: 35px;
      margin-left: 10px;
      background-color: #ffffff;
      border: 1px solid #87a5a8;
      border-radius: 5px;
      font-size: 1em;
      padding: 0 10px 0 10px;
      color: #5f6667;
    }

    .selectClasification:focus {
      outline: none;
    }

    .labelClasification {
      height: 35px;
      line-height: 33px;
    }
  }
}

.no-notes {
  height: 400px;
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  margin-top: -165px;

  .notesModal__notes {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    overflow-x: hidden;
    height: 128px;
  }

  .notesModal__addNote {
    height: 100%;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  .notesModal__addNote--hidden {
    display: none;
  }

  .notesModal__textArea {
    height: 175px;
  }
}

.notes_list_body {
  height: 250px;
  overflow: scroll;
}