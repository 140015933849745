@import 'assets/connect/styles/_variables';

$sideMargin: 16px;
$xMargin: 12px;

.inspectionForm {
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__sectionTitle {
    display: block;
    margin: 20px 0;
    font-weight: 600;
    padding: 0;
    color: $tundora;
  }

  &__input {
    width: 100%;
  }

  .chipLabel {
    min-width: 149px;
    margin-top: 3%;
  }
  .chipTop {
    margin-top: 1rem;
  }

  .title-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__title {
    color: $slate-gray;
    font-size: 20px;
  }

  &__tabRow {
    .accordionSection {
      width: 100%;
    }
    .MuiPaper-elevation1 {
      box-shadow: none;
    }
    .MuiTypography-root {
      margin-right: 12px;
    }
  }
  &__button,
  #document-btn {
    width: auto;
    align-self: flex-end;
    margin: 30px !important; // needed to override Material UI internal classes
    border-radius: 38px;
    text-transform: none;
  }
  &__button,
  #continue-btn {
    width: min-content;
    align-self: flex-end;
    margin: 30px !important; // needed to override Material UI internal classes
    border-radius: 38px;
    text-transform: none;
  }
  &__button,
  #back-btn {
    width: min-content;
    align-self: flex-end;
    margin: 30px !important; // needed to override Material UI internal classes
    border-radius: 38px;
    text-transform: none;
  }
  &__button,
  #request-btn {
    width: 20%;
    margin: 30px 0px 0px 0px !important;
    border-radius: 38px;
    text-transform: none;
  }
  &__toggle-button.MuiButton-outlinedPrimary {
    background-color: $white;
    border-color: $dusty-gray;
    color: $dusty-gray;
    margin: 0 4px;
    min-width: 100px;
  }
  &__toggle-button-error.MuiButton-outlinedPrimary {
    background-color: $white;
    border-color: #ff0000;
    color: #ff0000;
    margin: 0 4px;
    min-width: 100px;
  }
  &__toggle-button.MuiButton-outlinedPrimary:hover {
    background-color: rgba($color: $primary, $alpha: 0.7);
    color: $white;
  }
  &__toggle-button--selected.MuiButton-outlinedPrimary,
  &__toggle-button--selected.MuiButton-outlinedPrimary:hover {
    background-color: $primary;
    color: $white;
    border-color: $primary;
  }
}
span.MuiButton-label {
  pointer-events: none;
}

.croquis {
  position: relative;
  max-width: 600px;

  &__diagram {
    width: 100%;
    margin: 10px 0;
    // border: 3px #536882 solid;
    border-radius: 10px;
  }
  &__img {
    width: 100%;
    // border: 3px transparent solid;
    border-radius: 10px;
    margin-top: 10px;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    height: 100%;
  }
}

.sketch-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-x: auto;
  width: unset;
  height: 100vh;
  margin: auto;
  z-index: 99999;

  .eraserIcon {
    &.active {
      background-color: $primary;
    }
    position: fixed;
    background-color: white;
    top: 36px;
    right: 16px;
    z-index: 9999;
    border-radius: 90px;
    border-color: hsla(0, 0%, 0%, 0);
    padding: 8px;
    height: 44px;
    width: 44px;
    img {
      height: 26px;
    }
  }
  .container {
    &:focus {
      outline: none;
    }
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: max-content;

    .scroll-handle {
      background-color: $alto;
      font-size: 80%;
      padding: 4px 20px;
      width: 50%;
      color: $blue-bayoux;
      font-weight: bold;
      border-radius: 10px;
      text-align: center;
    }
  }
  .buttons-container {
    bottom: 16px;
    right: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: fixed;

    button.MuiButtonBase-root {
      margin: 0 4px;
      border-radius: 38px;
      width: 100%;
    }
  }
  .container:focus {
    outline: none;
  }
}

.damages-container {
  display: flex;
  flex-direction: row;
  width: 100%;

  .diagram {
    flex-grow: 1;
    max-width: 800px;

    .damagesModal__content {
      max-width: 400px;
    }

    .vehicleDamages__checks {
      .damageCheck,
      .damageCheck--selected {
        border-radius: 4px;
        width: 40px;
        height: 40px;
        font-size: 20px;
        border-color: $cool-gray;
      }
      .damageCheck--selected {
        border-color: $primary;
        background-color: $primary;
      }
    }
  }

  .damage-list {
    width: 100%;
    flex-grow: 1;
    ul {
      li {
        list-style-type: none;
        padding: 16px;
        border: 1px $cool-gray solid;
        border-radius: 6px;
        margin: 0;
        margin-bottom: 8px;
      }
    }
  }
}

.inspector-form {
  header.MuiAppBar-root {
    z-index: 999;
  }
  .dinamic-tab-form {
    .heading {
      flex-direction: column-reverse;
      .buttons {
        justify-content: flex-end;
        display: flex;
        width: 100%;
        svg {
          margin-right: 10px;
          height: 30px;
          width: 30px;
          margin-top: 10px;
          color: $blue-bayoux;
        }
      }
    }
  }
  .damages-container {
    flex-wrap: wrap;

    .diagram {
      width: 100%;
      max-width: none;
    }
    .damage-list {
      ul {
        padding: 0;
      }
    }
  }
}

.inspection {
  .MuiFormControl-root {
    textarea,
    input {
      text-transform: uppercase;
    }
  }
}

.dinamic-tab-form {
  .heading {
    display: flex;
    flex-direction: row;
    justify-content: baseline;
    label {
      margin-top: 16px;
    }

    .MuiTabs-root {
      width: 100%;
      flex-grow: 1;
    }

    .buttons {
      justify-content: flex-end;
      display: flex;

      svg {
        margin-right: 10px;
        height: 30px;
        width: 30px;
        margin-top: 10px;
        color: $blue-bayoux;
      }
    }
  }

  .MuiBox-root {
    padding: 0 !important;
  }
}

.rdError span .MuiIconButton-label {
  color: #f44336;
}

.radioError {
  color: #f44336;
}

.MuiFormControl-root {
  label.MuiFormLabel-root.MuiInputLabel-root {
    color: $slate-gray;
  }
  label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-shrink {
    color: black;
  }
  label.MuiFormLabel-root.MuiInputLabel-root.Mui-focused {
    color: $primary;
  }
  textarea,
  input {
    color: black;
  }
}

label.MuiFormLabel-root.MuiInputLabel-root {
  color: black;
}
.signature__placeholder {
  color: $slate-gray;
}

button.MuiButtonBase-root.inspectionForm__toggle-button--selected.Mui-disabled {
  color: rgba(white, 0.65);
}

.MuiSelect-root.MuiSelect-select {
  color: black;
}

span.MuiFormControlLabel-label.Mui-disabled {
  color: black;
}

.MuiButtonBase-root.MuiIconButton-root.MuiPickersDay-day.MuiPickersDay-dayDisabled {
  color: rgba(0, 0, 0, 0.38);
}

audio {
  width: 190px;
}

.audio-react-recorder {
  display: none;
}

.sound-wave {
  display: none;
}

.flex {
  display: flex;
}

.contents {
  display: contents;
}

.img-transcript {
  height: 30px;
  width: 35px;
  padding-top: 12px;
  cursor: pointer;
}

.time {
  text-align: center;
  padding-top: 15px;
  font-weight: bold;
}

.confirm-modal {
  text-align: center;
}

.chip-container {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
}

@media only screen and (max-width: $tablet) {
  .damages-container {
    flex-direction: column;

    .damage-list {
      ul {
        padding: 0;
      }
    }
  }
}
