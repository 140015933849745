@import '../../../assets/connect/styles/variables';

.scene {
  display: flex;
  width: 100%;
  max-width: 500px;
  margin: 0;
  flex-direction: column;

  Button {
    width: 100%;
  }

  .container {
    width: 100%;
    display: flex;
    margin: 0;
    flex-direction: column;
    align-items: center;
  }
  .map-container {
    height: 650px;
    max-width: 450px;
    width: 100%;
    display: flex;
    margin: 0;
    flex-direction: column;
  }
  .sketch-container {
    margin-top: 10px;
    height: 650px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-self: center;
  }

  Button.confirm {
    margin-top: 8px;
  }
}

Button.edit {
  width: 100%;
  margin-bottom: 16px;
}

.confirm-button {
  height: 36px;
  background-color: $primary;
  border-color: $primary;
  border-radius: 4px;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: normal;
  padding: 8px;
  text-transform: uppercase;
  margin: 0;
}

.photoOption {
  position: relative;
  display: flex;
  flex-direction: column;
  border: 4px solid $light-gray;
  border-radius: 12px;
  padding: 10px;
  color: $primary;
  font-weight: 600;
  align-items: center;
  justify-content: flex-end;
  background: $white;

  margin-left: 10px;
  margin-bottom: 20px;

  &--selected {
    border: 4px solid $fern;
  }

  &__icon {
    width: 120px;
    height: 120px;
  }

  &__label {
    position: absolute;
    top: -20px;
    background-color: $light-gray;
    color: $white;
    height: 20px;
    width: 20px;
    text-align: center;
    border-radius: 20px;

    &--selected {
      background-color: $fern;
    }
  }
}

.active {
  background-color: $primary;
  color: $white;
}

.scenesRow {
  display: flex;
  margin-top: 44px;
  margin-bottom: 24px;
  flex-wrap: wrap;
  justify-content: center;
}

.message-window-container .message-window .container {
  width: auto;
  margin: auto;
  max-width: 500px;
}

.sketch-container {
  .icon {
    max-width: 100px;
    max-height: 100px;
    img {
      max-width: 100px;
      max-height: 100px;
    }
  }
}

.message-window-container .message-window .container img.close{
  padding: 0;
}