@import 'assets/connect/styles/_variables';

/* Base styles */
.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: sans-serif;
  transition: all 0.3s ease;
  text-transform: uppercase;
  cursor: pointer;
  border: none;
  outline: none;
  font-family: Connect;

  /* Sizes */
  &.sm {
    padding: 8px 16px;
    font-weight: 900;
    font-size: 14px;
  }
  &.md {
    padding: 12px 24px;
    font-weight: 700;
    font-size: 16px;
  }
  &.lg {
    padding: 16px 32px;
    font-weight: 1000;
    font-size: 18px;
  }

  /* Radius */
  &.full {
    border-radius: 9999px;
  }
  &.none {
    border-radius: 0;
  }

  /* Variants */
  &.primary {
    background-color: $orange;
    color: white;
    box-shadow: 0px 0px 0px 0px rgba(241, 91, 43, 0.1)
    2px 3px 8px 0px rgba(241, 91, 43, 0.1)
    6px 13px 14px 0px rgba(241, 91, 43, 0.09)
    14px 29px 19px 0px rgba(241, 91, 43, 0.05)
    25px 51px 23px 0px rgba(241, 91, 43, 0.01)
    40px 79px 25px 0px rgba(241, 91, 43, 0);

    &:hover {
      background-color: $darkorange;
    }
    &:active {
      background-color: $activeOrage;
    }
    &:disabled {
      background-color: $grey-200;
      cursor: not-allowed;
      color: white;
      border-color: $grey-200;
    }
  }

  &.secondary {
    background-color: white;
    border: 2px solid $orange;
    color: $orange;

    &:hover {
      border: 2px solid $darkorange;
      color: $darkorange;
    }
    &:active {
      border: 2px solid $activeOrage;
      color: $activeOrage;
    }
    &:disabled {
      background-color: $grey-100;
      cursor: not-allowed;
      color: rgb(88 105 131);
      border-color: $grey-200;
    }
  }

  &.tertiary {
    color: $orange;
    background-color: transparent;

    &:hover {
      color: $darkorange;
    }
    &:disabled {
      background-color: $grey-100;
      cursor: not-allowed;
      color: rgb(88 105 131);
      border-color: $grey-200;
    }
    &:active {
      color: $activeOrage;
    }
  }

  /* Color Schemes */
  &.white {
    background-color: white;
    color: black;

    &:hover {
      background-color: lightgrey;
    }
  }

  &.orange {
    background-color: $orange;
    color: white;

    &:hover {
      background-color: $darkorange;
    }
  }

  &.blue {
    background-color: blue;
    color: white;

    &:hover {
      background-color: darkblue;
    }
  }

  /* Example compound styles */
  &.primary.white {
    background-color: white;
    color: $orange;

    &:hover {
      background-color: lightgrey;
    }
  }
}
