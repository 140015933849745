@import 'assets/connect/styles/_variables';

.footerBar {
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 73px;
  background-color: $white;
  padding: 0 20px;
  transition: width 195ms ease-in 0ms;
  z-index: 10;
  border-top: 1px solid #D9D9D9;

  &-header,
  &-filters {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 64px;
  }

  &--smaller {
    left: 200px;
    transition: width 225ms ease-out 0ms;
  }

  &__searchInput {
    display: flex;
    align-items: center;

    .filterText {
      min-width: 260px;
    }
  }

  &__searchIcon {
    color: $granny;
  }

  &__options {
    display: flex;
    align-items: center;
    color: $granny;
  }

  &__avatar {
    margin: 0 20px;
  }

  &__notificationsDot {
    height: 19px;
    width: 19px;
    position: absolute;
    top: -4px;
    right: -4px;
    border-radius: 16px;
    background-color: $red;
    color: white;
    font-size: 13px;
    padding: 2px;
  }

  &__filterType {
    display: flex;
    align-items: center;
    width: 100%;

    .MuiInputBase-root {
      border-radius: 6px;
      min-width: 125px;
    }

    .MuiSelect-select {
      height: 0.7rem;
      width: 6rem;
      padding: 0.7rem;
      background-color: #f1f4f7;
      border-radius: 6px;
      min-width: 125px;
    }

    .MuiSelect-select:focus {
      background-color: #f1f4f7;
      border-radius: 5px;
    }

    .MuiFilledInput-underline:before {
      border-bottom: 0px;
    }

    .MuiFilledInput-underline:hover:not(.Mui-disabled):before {
      border-bottom: 0px;
    }
  }
}

@media only screen and (max-width: $tablet) {
  .footerBar {
    left: 0;
  }
}

@media only screen and (max-width: $mobile) {
  .footerBar {
    &__searchInput {
      .filterText {
        min-width: 0;
        width: auto;
      }
    }
  }
}
